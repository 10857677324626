<div class="container">
  <mat-icon class="icon" [class.info] = "notification.target !== 'alert'">error</mat-icon>
  <div class="notification-timestamp">{{notificationTimeStamp}}</div>
  <h2 class="container__header" data-testid="notification-popup-title">{{ notification.title }}</h2>

  <div
    class="container__content"
    [innerHTML]="notification.content"
    data-testid="notification-popup-content"
  ></div>
</div>

<div class="actions">
  <button mat-button
          cdkFocusInitial
          class="actions__acknowledge"
          (click)="onClickAcknowledge()">
      <span [hidden]="notification.isAcknowledged || notification.target === 'critical'">ACKNOWLEDGE AND</span>
      CLOSE
  </button>
</div>
