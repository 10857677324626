import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import {
  Observable,
  finalize,
  switchMap,
  take,
} from 'rxjs';

import { LoadingService } from '../services/portfolio-admin-services/loading.service';
import { ParticipantService } from '../services/portfolio-admin-services/participant.service';

@Injectable()
export class PortfolioHierarchyInterceptor implements HttpInterceptor {
  private activeRequests = 0;

  constructor(
    private loadingService: LoadingService,
    private participantService: ParticipantService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(`${environment.portfolioAdminApiUrl}/participants`)) {
      this.loadingService.show();

      return next.handle(request).pipe(
        finalize(() => {
          this.loadingService.hide();
        }),
      );
    }

    if (request.url.includes(environment.portfolioAdminApiUrl) && !request.url.includes('/participants')) {
      this.activeRequests++;
      this.loadingService.show();

      return this.participantService.selectedParticipantId$.pipe(
        take(1),
        switchMap((selectedParticipantId) => {
          const modifiedReq = request.clone({
            setHeaders: { 'Participant-Id': selectedParticipantId },
          });
          return next.handle(modifiedReq);
        }),
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loadingService.hide();
          }
        }),
      );
    }
    return next.handle(request);
  }
}
