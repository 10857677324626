import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
} from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { directNavigationGuard } from './core/guards/direct-navigation.guard';
import { permissionGuard } from './core/guards/permission.guard';
import { AccessDeniedComponent } from './modules/error/containers/access-denied/access-denied.component';
import { ErrorContainerComponent } from './modules/error/containers/error-container/error-container.component';
import { HomeContainerComponent } from './modules/home/containers/home-container/home-container.component';

const routes: Routes = [
  {
    path: '',
    component: HomeContainerComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, permissionGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard, permissionGuard],
  },
  {
    path: 'error',
    component: ErrorContainerComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    canActivate: [AuthGuard, directNavigationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
