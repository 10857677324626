import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { NotificationModel } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService {
  constructor(private http: HttpClient) {
  }

  public createAcknowledgement$(feedId: string, notification: NotificationModel): Observable<unknown> {
    const requestModel = {
      recipient: feedId,
      acknowledgments: [
        {
          notificationId: notification.id,
        },
      ],
    };

    return this.http.post(environment.acknowledgmentsUrl, requestModel);
  }

  public deleteAcknowledgement$(feedId: string, acknowledgmentId: string): Observable<unknown> {
    return this.http.delete(`${environment.acknowledgmentsUrl}?recipient=${feedId}&id=${acknowledgmentId}`);
  }
}
