import { Injectable } from '@angular/core';

import { SetPointsResult } from '@bradyplc/brady.powerdesk.api.internal.setpoints.contracts';

import { DateTime } from 'luxon';

import { AssetHierarchyFacadeService } from '../../../modules/asset-hierarchy/services/asset-hierarchy-facade.service';
import { FeedsService } from '../feeds.service';
import { SignalRService } from '../signal-r.service';

@Injectable({
  providedIn: 'root',
})
export class SetPointsHandlerService {
  constructor(
    private feedsService: FeedsService,
    private signalRService: SignalRService,
    private assetHierarchyFacadeService: AssetHierarchyFacadeService,
  ) {}

  public initialize(): void {
    this.signalRService.hubConnection?.on(
      'onSetPointsUpdate',
      (event: string) => this.onSetPointsUpdate(event),
    );
  }

  private onSetPointsUpdate(event: string) {
    const result = JSON.parse(event) as SetPointsResult;

    const mapDatesToDateTime = {
      ...result,
      setPointsOpsStartTiers: result.setPointsOpsStartTiers.map((tier) => ({
        ...tier,
        effectiveFrom: DateTime.fromISO(tier.effectiveFrom as unknown as string),
      })),
      setPointsFlexTiers: result.setPointsFlexTiers.map((tier) => ({
        ...tier,
        effectiveFrom: DateTime.fromISO(tier.effectiveFrom as unknown as string),
      })),
    };

    if (!this.assetHierarchyFacadeService.getSelectedAssetGroup) {
      throw new Error('Asset group has not been selected yet');
    }

    const feedId: string = this.assetHierarchyFacadeService.getSelectedAssetGroup.id;
    const feed = this.feedsService.getFeedById(feedId);
    if (!feed) {
      throw new Error(`Feed "${feedId}" can't be found!`);
    }

    feed.data.setPoints = mapDatesToDateTime;
    this.feedsService.updateFeed(feed);
  }
}
