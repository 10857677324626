import { Injectable } from '@angular/core';

import {
  Acknowledgment,
  IntradayContract,
  MarketDepth,
  TimeSeriesSegment,
} from '@bradyplc/brady.powerdesk.api.internal.frontendfeeds.contracts';

import {
  BehaviorSubject,
  Observable,
  Subject,
} from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { LoggedUserService } from '../services/logged-user.service';
import { TimeSeriesHelperService } from '../services/time-series-helper.service';

import {
  Feed,
  NotificationResponse,
} from './models/feed';

@Injectable({
  providedIn: 'root',
})
export class FeedsService {
  private readonly feedMap: Record<string, BehaviorSubject<Feed>> = {};
  private mandatoryFeedIds$$ = new BehaviorSubject<string[]>([]);

  private latestNotificationUpdate$$ = new Subject<NotificationResponse>();
  public latestNotificationUpdate$ = this.latestNotificationUpdate$$.asObservable();

  constructor(private loggedUserService: LoggedUserService, private timeseriesHelper: TimeSeriesHelperService) {
  }

  public get feeds(): Feed[] {
    return Object.values(this.feedMap).map((feed$$) => feed$$.value);
  }

  public set mandatoryFeedIds(feedIds: string[]) {
    this.mandatoryFeedIds$$.next(feedIds);
  }

  public get mandatoryFeedIds(): string[] {
    return this.mandatoryFeedIds$$.value;
  }

  public get mandatoryFeedIds$(): Observable<string[]> {
    return this.mandatoryFeedIds$$.asObservable();
  }

  public get personalFeedId(): string {
    return `PD:Shared|user-${this.loggedUserService.loggedUser.id}`;
  }

  public getFeedById$(id?: string): Observable<Feed> {
    if (!id) {
      throw new Error('getFeedById$ was called without feed id!');
    }

    this.addFeed(new Feed(id));

    return this.feedMap[id].asObservable().pipe(distinctUntilChanged());
  }

  public getFeedById(id: string): Feed | undefined {
    return this.feedMap[id]?.value;
  }

  public addFeed(feed: Feed): void {
    if (!this.feedMap[feed.id]) {
      this.feedMap[feed.id] = new BehaviorSubject<Feed>(feed);
    }
  }

  public updateFeed(feed: Feed): void {
    if (!this.feedMap[feed.id]) {
      throw new Error(`Can't update feed "${feed.id}" because it's not found.`);
    }

    const updatedFeed = new Feed(feed.id);
    Object.assign(updatedFeed, feed);

    this.feedMap[feed.id].next(updatedFeed);
  }

  public removeFeed(id: string): void {
    delete this.feedMap[id];
  }

  public removeAllFeeds(): void {
    Object.keys(this.feedMap).forEach((key) => {
      delete this.feedMap[key];
    });
  }

  public truncateAllFeeds(): void {
    Object.keys(this.feedMap).forEach((feedId) => {
      this.feedMap[feedId].next(new Feed(feedId));
    });
  }

  public applyBufferedUpdates(feed: Feed): boolean {
    feed.buffer.removeUntil(feed.sequenceNumber + 1);
    if (!feed.buffer.isReady(feed.sequenceNumber)) {
      return false;
    }

    if (feed.sequenceNumber === -1) {
      return false;
    }

    let update = feed.buffer.shift();
    while (update) {
      const updateSequenceNumber = update.sequenceNumber;
      const { itemType } = update;
      const { deltaType } = update;
      const delta = JSON.parse(update.delta) as (
        NotificationResponse | Acknowledgment | TimeSeriesSegment | IntradayContract | MarketDepth
      );

      if (updateSequenceNumber <= feed.sequenceNumber) {
        throw Error('Update is too old!');
      }

      if (itemType === 'Brady.APIs.Application.Notifications.Models.Notification') {
        this.updateNotification(feed, delta as NotificationResponse, deltaType);
      } else if (itemType === 'Brady.APIs.Application.Notifications.Models.Acknowledgment') {
        this.updateAcknowledgement(feed, delta as Acknowledgment, deltaType);
      } else if (itemType === 'Brady.PowerDesk.DomainModels.Frontend.Models.TimeSeriesSegmentMessage') {
        this.updateTimeseries(feed, delta as TimeSeriesSegment, deltaType);
      } else if (itemType === 'Brady.PowerDesk.DomainModels.Frontend.Models.IntradayContractMessage') {
        this.updateContracts(feed, delta as IntradayContract, deltaType);
      } else if (itemType === 'Brady.PowerDesk.DomainModels.Frontend.Models.IntradayMarketDepthMessage') {
        feed.data.marketDepth = delta as MarketDepth;
      } else {
        throw Error(`Unexpected item type:${itemType}`);
      }

      feed.sequenceNumber = updateSequenceNumber;

      update = feed.buffer.shift();
    }

    this.updateFeed(feed);
    return true;
  }

  private updateNotification(feed: Feed, delta: NotificationResponse, deltaType: string): void {
    const itemIndex = feed.data.notifications.findIndex((x) => x.id === delta.id);
    switch (deltaType) {
      case 'append':
        if (itemIndex === -1) {
          feed.data.notifications = feed.data.notifications.push(delta);
          this.latestNotificationUpdate$$.next(delta);
        }

        break;
      case 'delete':
        if (itemIndex !== -1) {
          feed.data.notifications = feed.data.notifications.remove(itemIndex);
        }
        break;

      default:
        throw Error(`Unexpected delta type:${deltaType}`);
    }
  }

  private updateAcknowledgement(feed: Feed, delta: Acknowledgment, deltaType: string): void {
    switch (deltaType) {
      case 'append':
        feed.data.acknowledgments = feed.data.acknowledgments.push(delta);
        break;
      case 'delete': {
        const index = feed.data.acknowledgments.findIndex((x: Acknowledgment) => x.id === delta.id);
        if (index !== -1) {
          feed.data.acknowledgments = feed.data.acknowledgments.remove(index);
        }
        break;
      }
      default:
        throw Error(`Unexpected delta type:${deltaType}`);
    }
  }

  private updateTimeseries(feed: Feed, delta: TimeSeriesSegment, deltaType: string): void {
    switch (deltaType) {
      case 'update': {
        const index = feed.data.timeseriesSegments.findIndex((s) => (
          s.timeSeriesType === delta.timeSeriesType
          && s.ownerType === delta.ownerType
          && s.ownerId === delta.ownerId
        ));
        const mappedDelta = this.timeseriesHelper.mapTimeseriesSegment(delta);

        if (index === -1) {
          feed.data.timeseriesSegments = feed.data.timeseriesSegments.push(mappedDelta);
        } else {
          feed.data.timeseriesSegments = feed.data.timeseriesSegments.update(index, (segment) => (
            segment
              ? {
                ...segment,
                values: segment.values
                  .filter((v) => v.utcDate !== delta.utcDate)
                  .concat(mappedDelta.values),
              }
              : mappedDelta
          ));
        }
        break;
      }
      default:
        throw Error(`Unexpected delta type:${deltaType}`);
    }
  }

  private updateContracts(feed: Feed, delta: IntradayContract, deltaType: string) {
    const itemIndex = feed.data.contracts.findIndex((c) => c.id === delta.id);

    switch (deltaType) {
      case 'append':
        feed.data.contracts = feed.data.contracts.push(delta);
        break;
      case 'update':
        if (itemIndex === -1) {
          feed.data.contracts = feed.data.contracts.push(delta);
        } else {
          feed.data.contracts = feed.data.contracts.set(itemIndex, delta);
        }
        break;
      case 'delete':
        if (itemIndex !== -1) {
          feed.data.contracts = feed.data.contracts.delete(itemIndex);
        }
        break;
      default:
        throw Error(`Unexpected delta type:${deltaType}`);
    }
  }
}
