import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';

import { AvatarModule } from '@shared/avatar/avatar.module';
import { ClockModule } from '@shared/clock/clock.module';

import { ManageOrdersModule } from '../manage-orders/manage-orders.module';
import { PortfolioHierarchyModule } from '../portfolio-hierarchy/portfolio-hierarchy.module';

import { ActionBarContainerComponent } from './containers/action-bar-container/action-bar-container.component';
import { AppBarContainerComponent } from './containers/app-bar-container/app-bar-container.component';

@NgModule({
  declarations: [
    ActionBarContainerComponent,
    AppBarContainerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ClockModule,
    AvatarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
    MatRippleModule,
    MatBadgeModule,
    MatSlideToggleModule,
    PortfolioHierarchyModule,
    ManageOrdersModule,
  ],
  exports: [
    AppBarContainerComponent,
    ActionBarContainerComponent,
  ],
})
export class HeaderModule {
}
