import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AssetGroupResult } from '@bradyplc/brady.powerdesk.api.internal.systemreferencedata.contracts';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetHierarchyApiService {
  constructor(private httpClient: HttpClient) {}

  public getAssetHierarchy$(portfolioId: string): Observable<AssetGroupResult[]> {
    return this.httpClient.post<AssetGroupResult[]>(
      environment.assetHierarchyUrl,
      { portfolioId },
    );
  }
}
