<div class="container">
  <app-bar-container></app-bar-container>
  <div class="section-content-header">
    <mat-icon class="icon_warning">error</mat-icon>
    <h2 class="text-xxl">PowerDesk access denied.</h2>
  </div>
  <div class="section-content-body">
    <p class="text-md">Please contact your system administrator.</p>
    <p class="text-md">If you are a new user you probably haven't been assigned any roles. When you have been assigned roles, please refresh the page.</p>
  </div>
</div>
