import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { take } from 'rxjs/operators';

import {
  DeactivateOrdersConfirmationDialogComponent,
} from '../deactivate-orders-confirmation-dialog/deactivate-orders-confirmation-dialog.component';

@Component({
  selector: 'app-deactivate-orders-button',
  templateUrl: './deactivate-orders-button.component.html',
  styleUrls: ['./deactivate-orders-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeactivateOrdersButtonComponent {
  constructor(private dialog: MatDialog) {
  }

  public onClickDeactivateOrders(): void {
    this.dialog.open<DeactivateOrdersConfirmationDialogComponent>(
      DeactivateOrdersConfirmationDialogComponent,
      { panelClass: 'deactivate-orders-dialog-panel', width: '500px' },
    )
      .afterClosed()
      .pipe(take(1))
      .subscribe();
  }
}
