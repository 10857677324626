import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { TimeSeriesSegment } from '@bradyplc/brady.powerdesk.api.internal.frontendfeeds.contracts';
import { TimeSeriesResolution } from '@bradyplc/brady.powerdesk.api.internal.mfrr.contracts';
import { ContractTradingCloseTimeOffsetResult, CountryResult } from '@bradyplc/brady.powerdesk.api.internal.systemreferencedata.contracts';

import Big from 'big.js';
import {
  DateTime,
  Interval,
} from 'luxon';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  of,
  map,
  skip,
  Subscription,
  switchMap,
} from 'rxjs';

import { Units } from '@core/constants/units';
import { FeedsApiService } from '@core/feeds/feeds-api.service';
import { FeedsEngineService } from '@core/feeds/feeds-engine.service';
import { TimeSeriesHelperService } from '@core/services/time-series-helper.service';
import { UtilsGranularityService } from '@core/services/utils/utils-granularity.service';
import { CountryConfig } from '@core/state-store/models/country-config.model';
import { GranularityType } from '@core/state-store/models/granularity.model';
import { UIStateStore } from '@core/state-store/ui-state-store.service';

import { TimeseriesSegmentModel } from '../../../../core/feeds/models/feed';
import { SelectableAssetGroupModel } from '../../../asset-hierarchy/models/selectable-asset-group.model';
import { AssetHierarchyFacadeService } from '../../../asset-hierarchy/services/asset-hierarchy-facade.service';
import { SelectedPortfolioHierarchyModel } from '../../../portfolio-hierarchy/models/selected-portfolio-hierarchy.model';
import { PortfolioHierarchyFacadeService } from '../../../portfolio-hierarchy/services/portfolio-hierarchy-facade.service';
import { TimeSeriesSegmentNopTypeEnum } from '../../../summary-grid/models/time-series-segment-nop-type.enum';
import { TimeSeriesSegmentReserveCardTypeEnum } from '../../../summary-grid/models/time-series-segment-reserve-card-type.enum';
import { CardToggleGroupType } from '../../models/card-toggle-group.type';
import { CardToggleItem } from '../../models/card-toggle-item.interface';
import { CardPeriodClosingTimeEnum } from '../../models/card-togle-period-closing-time-enum';
import { NextActivePeriod } from '../../models/next-active-period';
import { PositionModel } from '../../models/position.model';
import { ReserveBiddingModel } from '../../models/reserve-bidding.model';

@Component({
  selector: 'app-card-toggle-group-container',
  templateUrl: './card-toggle-group-container.component.html',
  styleUrls: ['./card-toggle-group-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardToggleGroupContainerComponent implements OnDestroy {
  @Input() position?: PositionModel;
  @Input() reserveBidding?: ReserveBiddingModel;

  @Output() readonly selectCard = new EventEmitter<CardToggleGroupType>();

  public visibleCards: CardToggleItem[] = [];
  public isSelectedCardVisible:boolean = false;
  public cards: CardToggleItem[] = [
    {
      type: 'nop-summary',
      title: 'NOP Summary',
      name: 'nop-summary',
      isSelected: false,
      index: 0,
      contents: [
        {
          title: '1/4 H Portfolios',
          value: 0,
        },
        {},
        {},
        {
          title: '1/2 H Portfolios',
          value: 0,
        },
        {
          title: '1 H Portfolios',
          value: 0,
        },
      ],
    },
    {
      type: 'position',
      title: 'Position',
      name: 'volumetric-NOP',
      closingTime: CardPeriodClosingTimeEnum.EndOfHour,
      isSelected: false,
      index: 1,
      contents: [
        {
          title: 'Period',
          value: 0,
        },
        {
          title: TimeSeriesSegmentNopTypeEnum.NOP,
          unit: Units.POWER,
          value: 0,
          testId: 'position-nop',
        },
        {
          title: TimeSeriesSegmentNopTypeEnum.TotalContracts,
          unit: Units.POWER,
          value: 0,
          testId: 'position-total-contracts',
        },
        {
          title: TimeSeriesSegmentNopTypeEnum.HeadRoom,
          unit: Units.POWER,
          value: 0,
          testId: 'position-head-room',
        },
        {
          title: TimeSeriesSegmentNopTypeEnum.FootRoom,
          unit: Units.POWER,
          value: 0,
          testId: 'position-foot-room',
        },
      ],
    },
    {
      type: 'assets',
      title: 'Assets',
      name: 'assets',
      isSelected: false,
      index: 2,
      contents: [
        {
          title: 'Period',
          value: 0,
        },
        {
          title: 'Assets',
          value: 0,
        },
        {},
        {
          title: 'Generation',
          unit: Units.POWER,
          value: 0,
          testId: 'asset-generation',
        },
        {
          title: 'Consumption',
          unit: Units.POWER,
          value: 0,
          testId: 'asset-consumption',
        },
      ],
    },
    {
      type: 'reserve-bidding',
      title: 'Reserve Bidding',
      name: 'reserve-bidding',
      closingTime: CardPeriodClosingTimeEnum.FortyFiveMinutesBeforeHour,
      isSelected: false,
      index: 3,
      contents: [
        {
          title: 'Period',
          value: 0,
        },
        {
          title: TimeSeriesSegmentReserveCardTypeEnum.AvailableGeneration,
          unit: Units.POWER,
          value: 0,
          testId: 'reserve-bidding-available-generation',
        },
        {
          title: TimeSeriesSegmentReserveCardTypeEnum.ReserveMarketCommitment,
          unit: Units.POWER,
          value: 0,
          testId: 'reserve-bidding-reserve-market-commitment',
        },
        {
          title: TimeSeriesSegmentReserveCardTypeEnum.UpwardCommitment,
          unit: Units.POWER,
          value: 0,
          testId: 'reserve-bidding-upward-commitment',
        },
        {
          title: TimeSeriesSegmentReserveCardTypeEnum.DownwardCommitment,
          unit: Units.POWER,
          value: 0,
          testId: 'reserve-bidding-downward-commitment',
        },
      ],
    },
    {
      type: 'auction',
      title: 'Auction',
      name: 'auction',
      isSelected: false,
      index: 4,
      contents: undefined,
    },
  ];

  private nextActivePeriods$$ = new BehaviorSubject<NextActivePeriod[]>([]);
  private subscriptions = new Subscription();
  private contractTradingCloseTimeOffsets: ContractTradingCloseTimeOffsetResult[] = [];
  private granularity: GranularityType | undefined;

  constructor(
    private assetHierarchyFacade: AssetHierarchyFacadeService,
    private feedsEngine: FeedsEngineService,
    private feedsApi: FeedsApiService,
    private portfolioHierarchyFacade: PortfolioHierarchyFacadeService,
    private uiStateStore: UIStateStore,
    private timeseriesHelper: TimeSeriesHelperService,
    private cdr: ChangeDetectorRef,
    private utilsGranularityService: UtilsGranularityService,
  ) {
    this.subscriptions.add(
      combineLatest([
        this.uiStateStore.globalTimer$,
        this.uiStateStore.countryConfigState$,
        this.uiStateStore.granularityState$,
      ]).subscribe(
        ([, countryConfig, granularity]) => {
          this.contractTradingCloseTimeOffsets = countryConfig.contractTradingCloseTimeOffsets;
          this.granularity = granularity;
          this.nextActivePeriods$$.next(this.getNextActivePeriods());
        },
      ),
    );

    this.subscriptions.add(this.subscribeToPeriodAndPortfolioChanges());
    this.subscriptions.add(this.subscribeToTimeseriesChanges());

    this.subscriptions.add(
      combineLatest([
        this.portfolioHierarchyFacade.portfolioHierarchy$,
      ]).subscribe(([portfolioHierarchy]) => {
        this.addPortfolioCountToNopSummary(portfolioHierarchy);
      }),
    );

    this.subscriptions.add(
      this.portfolioHierarchyFacade.selectedPortfolioHierarchy$.pipe(
        filter((selectedPortfolioHierarchy) => !!selectedPortfolioHierarchy),
        switchMap((selectedPortfolioHierarchy) => {
          const feedId = this.getFeedId(selectedPortfolioHierarchy);
          return combineLatest([
            this.feedsEngine.getTimeseries(feedId),
            this.nextActivePeriods$,
            this.assetHierarchyFacade.selectedAssetHierarchy$,
          ]);
        }),
      ).subscribe(([timeSeriesSegments, nextActivePeriod, selectedAssetHierarchy]) => {
        if (!nextActivePeriod?.length) {
          return;
        }

        const nextActivePeriodStart = nextActivePeriod[1].start.toISO();
        this.addAssetDetailsToCard(timeSeriesSegments, nextActivePeriodStart, selectedAssetHierarchy);
      }),
    );
  }

  public get nextActivePeriods$(): Observable<NextActivePeriod[]> {
    return this.nextActivePeriods$$.asObservable().pipe(distinctUntilChanged((a, b) => {
      let isSame = true;

      if (a.length !== b.length) {
        isSame = false;
      } else {
        for (let i = 0; i < a.length; i++) {
          if (!a[i].start.equals(b[i].start) || !a[i].end.equals(b[i].end)) {
            isSame = false;
            break;
          }
        }
      }

      return isSame;
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onSelectCard(item: CardToggleGroupType): void {
    this.visibleCards.forEach((card) => {
      if (card.type !== item) {
        card.isSelected = false;
      } else {
        card.isSelected = true;
      }
    });
    this.uiStateStore.setActiveScreenState(item);
    this.selectCard.emit(item);
  }

  private addAssetDetailsToCard(
    timeSeriesSegments: Immutable.List<TimeseriesSegmentModel>,
    nextActivePeriodStart: string,
    selectedAssetHierarchy: SelectableAssetGroupModel[],
  ): void {
    const assetsCard = this.cards.find((x) => x.type === 'assets') as CardToggleItem;

    if (!assetsCard.contents) {
      return;
    }

    const assetIds = selectedAssetHierarchy.flatMap((assetGroup) => assetGroup.assets.map((asset) => asset.id));

    let generation = 0;
    let consumption = 0;

    timeSeriesSegments.forEach((timeseriesSegment) => {
      if (assetIds.includes(timeseriesSegment.ownerId) && timeseriesSegment.timeSeriesType === 'Prod Plan') {
        timeseriesSegment.values
          .filter((ts) => ts.start.toISO() === nextActivePeriodStart)
          .forEach((timeSeriesValue) => {
            const prodPlanValue = Big(timeSeriesValue.numericValue).round(1, Big.roundDown).toNumber();

            if (prodPlanValue > 0) {
              generation += prodPlanValue;
            }
            if (prodPlanValue < 0) {
              consumption += Big(prodPlanValue).abs().toNumber();
            }
          });
      }
    });

    assetsCard.contents[1].value = assetIds?.length ?? 0;
    assetsCard.contents[3].value = Big(generation).round(1, Big.roundDown).toNumber();
    assetsCard.contents[4].value = Big(consumption).round(1, Big.roundDown).toNumber();

    assetsCard.contents = structuredClone(assetsCard.contents);
    this.cdr.markForCheck();
  }

  private addPortfolioCountToNopSummary(portfolioHierarchy: CountryResult[]): void {
    const result = {} as { [key: string]: number };
    const nopSummaryCard = this.cards.find((x) => x.type === 'nop-summary') as CardToggleItem;

    if (!nopSummaryCard.contents) {
      return;
    }

    for (const portfolio of portfolioHierarchy) {
      const { nopSummaryResolution } = portfolio;
      let portfolioCount = 0;

      portfolioCount += portfolio.deliveryAreas.reduce((pfCount, dArea) => pfCount + dArea.portfolios.length, 0);

      if (result[nopSummaryResolution]) {
        result[nopSummaryResolution] += portfolioCount;
      } else {
        result[nopSummaryResolution] = portfolioCount;
      }
    }

    nopSummaryCard.contents[0].value = result[TimeSeriesResolution.PT15M] ?? 0;
    nopSummaryCard.contents[3].value = result[TimeSeriesResolution.PT30M] ?? 0;
    nopSummaryCard.contents[4].value = result[TimeSeriesResolution.PT60M] ?? 0;
  }

  private getFeedId(selectedPortfolioHierarchy: SelectedPortfolioHierarchyModel | null): string {
    const resolution = this.utilsGranularityService.convertGranularityTypeToText(this.uiStateStore.granularityState);
    return `${selectedPortfolioHierarchy?.participantId}|timeseries-portfolio-${selectedPortfolioHierarchy?.portfolioId}-${resolution}`;
  }

  private getResolutionAndOffset(granularity: NonNullable<GranularityType>): { resolution: TimeSeriesResolution; offset: number } {
    let resolution = this.utilsGranularityService.granularityTypeToTimeseriesResolution(granularity);
    let offset = (this.contractTradingCloseTimeOffsets.find((x) => x.resolution as TimeSeriesResolution === resolution))?.offset;
    if (!offset) {
      resolution = TimeSeriesResolution.PT60M;
      offset = this.contractTradingCloseTimeOffsets.find((x) => x.resolution as TimeSeriesResolution === resolution)?.offset ?? 60;
    }

    return { resolution, offset };
  }

  private getNextActivePeriods(utc: boolean = false): NextActivePeriod[] {
    if (!this.granularity || this.contractTradingCloseTimeOffsets.length === 0) {
      return [];
    }

    const { resolution: selectedResolution, offset } = this.getResolutionAndOffset(this.granularity);
    const selectedResolutionMinutes = this.utilsGranularityService.timeseriesResolutionMinuteMap[selectedResolution];
    const timeForPeriodCalculation = DateTime.now().plus({ minutes: (selectedResolutionMinutes + offset) });

    let positionNextPeriodStart = timeForPeriodCalculation.startOf('hour').plus({ minutes: Math.floor(timeForPeriodCalculation.minute / selectedResolutionMinutes) * selectedResolutionMinutes });
    let reserveBiddingNextPeriodStart = this.isItFirstQuarterHour() ? DateTime.now().startOf('hour').plus({ hours: 1 }) : DateTime.now().startOf('hour').plus({ hours: 2 });

    positionNextPeriodStart = utc ? positionNextPeriodStart.toUTC() : positionNextPeriodStart;
    reserveBiddingNextPeriodStart = utc ? reserveBiddingNextPeriodStart.toUTC() : reserveBiddingNextPeriodStart;

    const positionNextActivePeriod : NextActivePeriod = {
      start: positionNextPeriodStart,
      end: positionNextPeriodStart.plus({ minute: selectedResolutionMinutes }),
    };

    const reserveBiddingNextActivePeriod : NextActivePeriod = {
      start: reserveBiddingNextPeriodStart,
      end: reserveBiddingNextPeriodStart.plus({ minute: 60 }),
    };

    return [positionNextActivePeriod, positionNextActivePeriod, positionNextActivePeriod, reserveBiddingNextActivePeriod];
  }

  private isItFirstQuarterHour(): boolean {
    const currentMinute = DateTime.now().minute;
    if (currentMinute >= 0 && currentMinute < 15) return true;
    return false;
  }

  private setActivePeriodInTimeSeriesHelper(positionPeriodDateTime: DateTime, reservBiddingPeriodDateTime: DateTime): void {
    this.timeseriesHelper.setActivePeriod([
      {
        screen: 'position',
        periodStartTime: positionPeriodDateTime,
      },
      {
        screen: 'reserve-bidding',
        periodStartTime: reservBiddingPeriodDateTime,
      },
    ]);
  }

  private setSelectedCard(countryConfig: CountryConfig): void {
    const nopSummaryCard = this.cards.find((x) => x.type === 'nop-summary') as CardToggleItem;
    const auctionCard = this.cards.find((x) => x.type === 'auction') as CardToggleItem;
    const activeScreen = this.uiStateStore.activeScreenState;

    this.visibleCards = [auctionCard, nopSummaryCard];

    countryConfig.screens.forEach(((screen) => {
      const card = this.cards.find((x) => x.name === screen);

      if (!card) {
        return;
      }

      this.visibleCards.push(card);
    }));

    this.visibleCards.forEach((card) => { card.isSelected = false });
    const selectedCard = this.visibleCards.find((card) => card.type === activeScreen);

    // Found previously selected card in local storage
    if (selectedCard) {
      selectedCard.isSelected = true;
      this.selectCard.emit(selectedCard.type);
      return;
    }

    const defaultCard = this.visibleCards.find((card) => card.type === 'auction') as CardToggleItem;
    defaultCard.isSelected = true;
    this.selectCard.emit(defaultCard.type);
  }

  private subscribeToPeriodAndPortfolioChanges(): Subscription {
    return combineLatest([
      this.nextActivePeriods$,
      this.portfolioHierarchyFacade.selectedPortfolioHierarchy$,
      this.uiStateStore.countryConfigState$,
    ]).pipe(
      distinctUntilChanged((a, b) => {
        const [prevActivePeriods, prevPortfolio, previousCountry] = a;
        const [nextActivePeriods, nextPortfolio, nextCountry] = b;
        return prevActivePeriods === nextActivePeriods
        && prevPortfolio?.portfolioId === nextPortfolio?.portfolioId && previousCountry.screens === nextCountry.screens;
      }),
      switchMap(([nextActivePeriods, portfolio, countryConfig]) => {
        if (nextActivePeriods.length === 0 || countryConfig.screens.length === 0) {
          return of({ snapshot: { timeseriesSegments: [] as TimeSeriesSegment[] } });
        }

        this.setSelectedCard(countryConfig);

        this.visibleCards.forEach((card) => {
          if (card.type !== 'nop-summary' && card?.contents) {
            card.contents[0].value = this.getSettlementPeriod(nextActivePeriods, card);
          }
        });

        const periodStart = nextActivePeriods[1].start < nextActivePeriods[3].start
          ? nextActivePeriods[1].start
          : nextActivePeriods[3].start;

        const periodEnd = nextActivePeriods[1].end > nextActivePeriods[3].end
          ? nextActivePeriods[1].end
          : nextActivePeriods[3].end;

        this.setActivePeriodInTimeSeriesHelper(nextActivePeriods[1].start, nextActivePeriods[3].start);
        const period = { start: periodStart, end: periodEnd };

        if (!portfolio) {
          return of({ snapshot: { timeseriesSegments: [] as TimeSeriesSegment[] }, period });
        }

        const { resolution } = this.getResolutionAndOffset(this.granularity as NonNullable<GranularityType>);
        const snapshotResolution = this.utilsGranularityService.timeseriesResolutionMinuteMap[resolution];
        const { participantId, portfolioId } = portfolio as Required<SelectedPortfolioHierarchyModel>;
        const feedId = `${participantId}|timeseries-portfolio-${portfolioId}-${snapshotResolution}`;
        return this.feedsApi.loadSnapshot$(feedId, 1, period).pipe(map((snapshot) => ({ snapshot, period })));
      }),
    ).subscribe((hourlySnapshot) => {
      this.visibleCards.forEach((card) => {
        if (!card.contents || card.type === 'assets' || card.type === 'nop-summary') {
          return;
        }

        const periodExcludedContent = card.contents?.filter((cardContent) => cardContent.title !== 'Period');

        periodExcludedContent.forEach((cardContent) => {
          const filteredSegment = hourlySnapshot.snapshot.timeseriesSegments
            .find((ts) => (
              ts.timeSeriesType === cardContent.title
            && ts.ownerType === 'Portfolio'
            && ts.ownerId === this.portfolioHierarchyFacade.selectedPortfolioId
            ));

          const nextActivePeriod = this.getNextActivePeriods()[card.index];
          const startPeriod = nextActivePeriod.start;
          const endPeriod = nextActivePeriod.end;

          const filteredValues = filteredSegment
            ? filteredSegment.values.filter((ts) => DateTime.fromISO(ts.start) >= startPeriod
          && DateTime.fromISO(ts.end) <= endPeriod) : [];

          const valueSum: number = filteredValues.reduce((prev, current) => prev + current.numericValue, 0);
          cardContent.value = this.timeseriesHelper.adjustNumber(valueSum === 0 ? 0 : valueSum / filteredValues.length, 1);
        });

        card.contents = structuredClone(card.contents);
        this.cdr.markForCheck();
      });
    });
  }

  private subscribeToTimeseriesChanges(): Subscription {
    return this.portfolioHierarchyFacade.selectedPortfolioHierarchy$.pipe(
      filter((selectedPortfolioHierarchy) => selectedPortfolioHierarchy !== null),
      switchMap((selectedPortfolioHierarchy) => {
        const feedId = this.getFeedId(selectedPortfolioHierarchy);
        return this.feedsEngine.getTimeseries(feedId).pipe(skip(1));
      }),
    ).subscribe((timeSeriesSegments) => {
      this.visibleCards.forEach((card) => {
        if (!card.contents) {
          return;
        }

        let isContentUpdated = false;
        card.contents.forEach((cardContent) => {
          const filteredSegment = timeSeriesSegments.find((s) => (
            s.timeSeriesType === cardContent.title
            && s.ownerType === 'Portfolio'
            && s.ownerId === this.portfolioHierarchyFacade.selectedPortfolioId
          ));

          const nextActivePeriod = this.getNextActivePeriods(true)[card.index];
          const startPeriod = nextActivePeriod.start;
          const endPeriod = nextActivePeriod.end;

          const filteredValues = filteredSegment
            ? filteredSegment.values.filter((ts) => ts.start.toUTC() >= startPeriod && ts.end.toUTC() <= endPeriod)
            : [];

          if (filteredValues.length > 0) {
            const valueSum: Big = filteredValues.reduce((prev, current) => prev.plus(current.numericValue), Big(0));
            cardContent.value = valueSum.div(filteredValues.length).round(1, Big.roundDown).toNumber();
            isContentUpdated = true;
          }
        });

        if (isContentUpdated) {
          card.contents = structuredClone(card.contents);
          this.cdr.markForCheck();
        }
      });
    });
  }

  private getSettlementPeriod(nextActivePeriods: NextActivePeriod[], card: CardToggleItem) : number {
    if (card.type === 'reserve-bidding') {
      return Interval.fromDateTimes(nextActivePeriods[card.index].start.startOf('day'), nextActivePeriods[card.index].start).length('hours') + 1;
    }

    if (card.type === 'position' || card.type === 'assets') {
      const timeInterval = Interval.fromDateTimes(nextActivePeriods[card.index].start.startOf('day'), nextActivePeriods[card.index].start).length('hours');
      const selectedResolutionDuration = nextActivePeriods[card.index].end.diff(nextActivePeriods[card.index].start, 'minutes').minutes;
      return timeInterval * (60 / selectedResolutionDuration) + 1;
    }

    return 0;
  }
}
