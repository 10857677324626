import { Injectable } from '@angular/core';

import { NGXLogger } from 'ngx-logger';

import { TelemetryEvents } from '../../models/telemetry.enum';
import { TelemetryService } from '../../services/telemetry.service';
import { FeedsService } from '../feeds.service';
import { SignalRService } from '../signal-r.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionHandlersService {
  constructor(
    private feedsService: FeedsService,
    private signalRService: SignalRService,
    private logger: NGXLogger,
    private telemetryService: TelemetryService,
  ) {
  }

  public initialize(): void {
    this.signalRService.hubConnection?.onclose((error) => {
      this.logConnectionEvent(TelemetryEvents.SignalRConnectionClosed, error);
      this.feedsService.truncateAllFeeds();
    });

    this.signalRService.hubConnection?.onreconnected(() => this.logConnectionEvent(TelemetryEvents.SignalRConnectionReconnected));
    this.signalRService.hubConnection?.onreconnecting(
      (error) => this.logConnectionEvent(TelemetryEvents.SignalRConnectionReconnecting, error),
    );
  }

  private logConnectionEvent(eventName: string, error?: Error) {
    if (error) {
      this.logger.error(error);
      this.telemetryService.logEvent(eventName, { error });
    } else {
      this.telemetryService.logEvent(eventName);
    }
  }
}
