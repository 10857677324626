import { Injectable } from '@angular/core';

import { UpdateResult } from '@bradyplc/brady.powerdesk.api.internal.frontendfeeds.contracts';

import { ProgressIndicatorService } from '../../services/progress-indicator.service';
import { FeedsService } from '../feeds.service';
import { SignalRService } from '../signal-r.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateHandlerService {
  constructor(
    private feedsService: FeedsService,
    private signalRService: SignalRService,
    private progressIndicator: ProgressIndicatorService,
  ) {
  }

  public initialize(): void {
    this.signalRService.hubConnection?.on(
      'onUpdate',
      (event: string) => this.onUpdate(event),
    );
  }

  private onUpdate(event: string) {
    const updates: UpdateResult[] = JSON.parse(event) as UpdateResult[];

    for (const update of updates) {
      const feed = this.feedsService.getFeedById(update.feedId);
      if (!feed) {
        throw new Error(`Current feed "${update.feedId}" can't be found!`);
      }

      feed.buffer.push(update);

      if (feed.id.includes('timeseries')) {
        this.progressIndicator.displayProgressIndicator();
      }

      this.feedsService.applyBufferedUpdates(feed);
    }
  }
}
