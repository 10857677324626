import { Injectable } from '@angular/core';

import {
  GetSetPointsQuery,
  SetPointsResult,
  UpdateSetPointsCommand,
} from '@bradyplc/brady.powerdesk.api.internal.setpoints.contracts';

import { Observable, BehaviorSubject } from 'rxjs';

import { SetPointsService } from './set-points.service';

@Injectable({
  providedIn: 'root',
})
export class SetPointsFacadeService {
  private showPanel$$ = new BehaviorSubject<boolean>(false);
  public showPanel$: Observable<boolean> = this.showPanel$$.asObservable();

  constructor(private setPointsService: SetPointsService) {
  }

  public get showPanel(): boolean {
    return this.showPanel$$.value;
  }

  public togglePanel(shouldShow: boolean): void {
    this.showPanel$$.next(shouldShow);
  }

  public getSetPoints$(setPointsGetRequest: GetSetPointsQuery): Observable<SetPointsResult> {
    return this.setPointsService.getSetPoints$(setPointsGetRequest);
  }

  public updateSetPoints$(setPointsSaveRequest: UpdateSetPointsCommand): Observable<void> {
    return this.setPointsService.updateSetPoints$(setPointsSaveRequest);
  }
}
