<div class="container">
  <mat-icon mat-dialog-close class="icon-close">close</mat-icon>

  <mat-icon class="icon-cancel">error</mat-icon>

  @if (!isTypeValidation) {
    <h2 class="container-header">{{ data.title }}</h2>
  }

  <div class="container-content" [class.container-content-without-header]="isTypeValidation" data-testid="error-popup-content">
    {{ data.content }}
  </div>
</div>

@if (!isTypeValidation) {
  <div class="actions">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-flat-button color="primary" cdkFocusInitial (click)="onClickTryAgain()">TRY AGAIN</button>
  </div>
}

@if (isTypeValidation) {
  <div class="actions actions-close">
    <button mat-flat-button mat-dialog-close color="primary" cdkFocusInitial>CLOSE</button>
  </div>
}
