import { UpdateResult } from '@bradyplc/brady.powerdesk.api.internal.frontendfeeds.contracts';

export class UpdateBuffer {
  private buffer: UpdateResult[] = [];

  public push(update: UpdateResult): number {
    // de-dup
    if (
      this.buffer.findIndex(
        (x) => x.sequenceNumber === update.sequenceNumber,
      ) !== -1
    ) {
      return this.buffer.length;
    }

    const count = this.buffer.push(update);
    this.buffer.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    return count;
  }

  public shift(): UpdateResult | undefined {
    return this.buffer.shift();
  }

  public removeUntil(sequenceNumber: number): void {
    let index = -1;

    for (let i = this.buffer.length - 1; i >= 0; i--) {
      if (this.buffer[i].sequenceNumber < sequenceNumber) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      this.buffer.splice(0, index + 1);
    }
  }

  public getSize(): number {
    return this.buffer.length;
  }

  public getSequenceNumber(): number {
    if (this.buffer.length === 0) {
      return -1;
    }

    return this.buffer[0].sequenceNumber;
  }

  public isBufferAhead(sequenceNumber: number): boolean {
    if (this.buffer.length === 0) {
      return false;
    }
    return sequenceNumber < this.buffer[0].sequenceNumber;
  }

  public isReady(sequenceNumber: number): boolean {
    if (this.buffer.length === 0) {
      return false;
    }

    // The buffer is starting after the input sequenceNumber and has no gaps
    let prevSequenceNumber = sequenceNumber;
    for (let i = 0; i < this.buffer.length; i++) {
      const update = this.buffer[i];
      if (update.sequenceNumber - prevSequenceNumber !== 1) {
        return false;
      }
      prevSequenceNumber = update.sequenceNumber;
    }

    return true;
  }
}
