export enum TimeSeriesSegmentNopTypeEnum {
  StartTime = 'Start Time',
  ProdPlan = 'Prod Plan',
  MaxGen = 'Max Gen',
  MinGen = 'Min Gen',
  NOP = 'NOP',
  OpenOrders = 'Open Orders',
  TotalContracts = 'Total Contracts',
  TotalCommitments = 'Total Commitments',
  HeadRoom = 'Head Room',
  FootRoom = 'Foot Room',
  AncillaryUp = 'Ancillary Up',
  AncillaryDown = 'Ancillary Down',
}
