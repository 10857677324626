import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { CardToggleItemComponent } from './components/card-toggle-item/card-toggle-item.component';
import { CardToggleGroupContainerComponent } from './containers/card-toggle-group-container/card-toggle-group-container.component';
import { CardItemNamePipe } from './pipes/card-item-name.pipe';

@NgModule({
  declarations: [
    CardToggleGroupContainerComponent,
    CardToggleItemComponent,
    CardItemNamePipe,
  ],
  exports: [
    CardToggleGroupContainerComponent,
  ],
  imports: [
    CommonModule,
    MatRippleModule,
    MatIconModule,
  ],
})
export class CardToggleGroupModule {
}
