<div matRipple class="card" [class.card--selected]="selected">
  <header class="card__header">
    <h3 class="card__header-text">{{ title }}</h3>
    @if (cardContent) {
      <div class="card__period">
        <span class="card__name">{{ cardContent[0].title }}</span>
        <span class="card__value" [attr.data-testid]="'card' + title?.replace(' ', '') + '-span-period'">
          {{ cardContent[0].value }}
        </span>
      </div>
    }
  </header>

  @if (cardContent) {
    <div class="card__inner">
      <div class="card__section">
        <div class="card__item">
          <span class="card__name">{{ cardContent[1].title | cardItemName }}</span>
          <span class="card__value" [attr.data-testid]="cardContent[1].testId">
            {{ cardContent[1].value }} {{ cardContent[1].unit }}
          </span>
        </div>
        <div class="card__item">
          <span class="card__name">{{ cardContent[2].title | cardItemName }}</span>
          <span class="card__value" [attr.data-testid]="cardContent[2].testId">
            {{ cardContent[2].value }} {{ cardContent[2].unit }}
          </span>
        </div>
      </div>
      <div class="card__section-separator"></div>
      <div class="card__section">
        <div class="card__item">
          <span class="card__name">{{ cardContent[3].title | cardItemName }} </span>
          <span class="card__value" [attr.data-testid]="cardContent[3].testId">
            {{ cardContent[3].value }} {{ cardContent[3].unit }}
          </span>
        </div>
        <div class="card__item">
          <span class="card__name">{{ cardContent[4].title | cardItemName }} </span>
          <span class="card__value" [attr.data-testid]="cardContent[4].testId">
            {{ cardContent[4].value }} {{ cardContent[4].unit }}
          </span>
        </div>
      </div>
    </div>
  }
</div>
