import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { LicenseManager } from '@ag-grid-enterprise/core';
import { environment } from '@env/environment';
import { combineLatest, distinctUntilChanged } from 'rxjs';

import { UIStateStore } from '@core/state-store/ui-state-store.service';

import { PortfolioHierarchyFacadeService } from '../../../portfolio-hierarchy/services/portfolio-hierarchy-facade.service';
import { AuctionsStateService } from '../../services/auctions-state.service';
import { AuctionOrderContainerComponent } from '../auction-order-container/auction-order-container.component';
import { AuctionResultContainerComponent } from '../auction-result-container/auction-result-container.component';
import { AuctionSummaryContainerComponent } from '../auction-summary-container/auction-summary-container.component';
import { ContainerActionsComponent } from '../shared/container-actions/container-actions.component';

LicenseManager.setLicenseKey(environment.agGridLicenseKey);

@Component({
  selector: 'app-auction-container',
  standalone: true,
  imports: [
    AuctionSummaryContainerComponent,
    AuctionOrderContainerComponent,
    AuctionResultContainerComponent,
    ContainerActionsComponent,
    CommonModule,
  ],
  templateUrl: './auction-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuctionContainerComponent implements OnDestroy, OnInit {
  public auctionView$ = this.auctionServiceState.auctionStateView$;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly auctionServiceState: AuctionsStateService,
    private readonly portfolioHierarchyFacadeService: PortfolioHierarchyFacadeService,
    private readonly uiStateStore: UIStateStore,
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.portfolioHierarchyFacadeService.selectedPortfolioHierarchy$,
      this.uiStateStore.periodState$,
    ]).pipe(
      distinctUntilChanged((prev, curr) => curr[0]?.countryId === prev[0]?.countryId
          && curr[0]?.deliveryAreaEicCode === prev[0]?.deliveryAreaEicCode
          && curr[0]?.portfolioId === prev[0]?.portfolioId
          && curr[0]?.participantId === prev[0]?.participantId
          && curr[1] === prev[1]),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.auctionServiceState.setView('summary');
    });
  }

  public ngOnDestroy(): void {
    this.auctionServiceState.setView('summary');
  }
}
