import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { NotificationModel } from '../../modules/notifications/models/notification.model';

@Component({
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  selector: 'app-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ErrorBannerComponent implements OnChanges {
  @Input() public banners?: NotificationModel[] | null;
  public index:number = 1;
  public numberOfItems :number = 0;
  public title:string | undefined;
  public content:string | undefined;
  public isNextButtonDisabled: boolean = false;
  public isPreviousButtonDisabled: boolean = true;

  public ngOnChanges(): void {
    if (this.banners) {
      this.numberOfItems = this.banners.length;
      this.setBannerContents();
    }
  }

  public getCounterText(): string {
    return `${(this.index).toString()} of ${this.numberOfItems.toString()}`;
  }

  public onClickNextBanner(): void {
    if (this.index < this.numberOfItems) {
      this.index++;
      this.setButtonsActiveStatus();
      this.setBannerContents();
    }
  }

  public onClickPreviousBanner(): void {
    if (this.index > 0) {
      this.index--;
      this.setButtonsActiveStatus();
      this.setBannerContents();
    }
  }

  public setButtonsActiveStatus():void {
    if (this.index - 1 === 0) {
      this.isNextButtonDisabled = false;
      this.isPreviousButtonDisabled = true;
    } else if (this.index === this.numberOfItems) {
      this.isNextButtonDisabled = true;
      this.isPreviousButtonDisabled = false;
    } else {
      this.isNextButtonDisabled = false;
      this.isPreviousButtonDisabled = false;
    }
  }

  public setBannerContents(): void {
    if (this.banners && this.banners.length > 0) {
      this.title = this.banners[this.index - 1].title;
      this.content = this.banners[this.index - 1].content;
    } else {
      this.title = '';
      this.content = '';
    }
  }

  public isMultipleBannersMode():boolean {
    if (this.banners && this.banners.length > 1) {
      return true;
    }
    return false;
  }
}
