import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { TelemetryEvents, TelemetryQtLocation } from '../models/telemetry.enum';

@Injectable({
  providedIn: 'root',
})
export class TelemetryService {
  public appInsights: ApplicationInsights;
  public tenantId = '';
  private _participantId = '';
  private quickTradeOpenedLocation: TelemetryQtLocation | undefined;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsKey,
        enableAutoRouteTracking: true, // option to log all route changes
        disableTelemetry: !environment.production || !!navigator.webdriver,
      },
    });
    this.appInsights.loadAppInsights();
  }

  public set participantId(participantId: string | undefined) {
    this._participantId = participantId ?? '';
  }

  public logPageView(name?: string, url?: string): void { // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url,
    });
  }

  public logEvent(name: string, properties?: { [key: string]: unknown }): void {
    this.processEvent(name, properties);

    this.appInsights.trackEvent({ name }, {
      ...properties,
      participantId: this._participantId,
      tenantId: this.tenantId,
    });
  }

  public logMetric(name: string, average: number, properties?: { [key: string]: unknown }): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  public logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception, severityLevel });
  }

  public logTrace(message: string, properties?: { [key: string]: unknown }): void {
    this.appInsights.trackTrace({ message }, properties);
  }

  public setUserId(userId: string) : void {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  public getQuickTradeOpenedLocation(): TelemetryQtLocation | undefined {
    return this.quickTradeOpenedLocation;
  }

  private processEvent(telemetryEvent: string, properties?: { [key: string]: unknown }): void {
    if (telemetryEvent === TelemetryEvents.QuickTradeOpened) {
      this.quickTradeOpenedLocation = properties?.['location'] as TelemetryQtLocation;
    }

    if (telemetryEvent === TelemetryEvents.QuickTradeClosed) {
      this.quickTradeOpenedLocation = undefined;
    }
  }
}
