import { Injectable } from '@angular/core';

import { TimeSeriesSegment, TimeSeriesValue } from '@bradyplc/brady.powerdesk.api.internal.frontendfeeds.contracts';

import {
  CellClassParams, ColDef, GridApi, RowNode, ValueGetterParams, ValueParserParams, ValueSetterParams,
} from '@ag-grid-community/core';
import Big from 'big.js';
import { List } from 'immutable';
import { DateTime, Interval } from 'luxon';
import { BehaviorSubject } from 'rxjs';

import { ActivePeriod } from '@shared/models/active-period.model';
import { GridChartSyncHelperService } from '@shared/services/grid-chart-sync-helper.service';
import {
  emptyFillerColumn,
  fillerColumnFieldName,
  getColumnWidth,
} from '@shared/utils/common-grid-definitions';

import { NullableNumberArray } from '../../modules/summary-grid/models/generate-chart-options.model';
import { TimeSeriesSegmentTypeEnum } from '../../modules/summary-grid/models/time-series-segment-type.enum';
import { TimeseriesSegmentModel, TimeseriesValueModel } from '../feeds/models/feed';
import { GridColumnPeriod } from '../models/grid-column-period.model';
import { GridRowData } from '../models/grid-row-data.model';
import { GranularityType } from '../state-store/models/granularity.model';
import { UIStateStore } from '../state-store/ui-state-store.service';

import { UtilsGranularityService } from './utils/utils-granularity.service';

@Injectable({
  providedIn: 'root',
})
export class TimeSeriesHelperService {
  constructor(
    private uiStateStore: UIStateStore,
    private utilsGranularityService: UtilsGranularityService,
    private gridChartSyncHelperService: GridChartSyncHelperService,
  ) {}

  private activePeriod$$ = new BehaviorSubject<ActivePeriod[]>([]);
  public activePeriod$ = this.activePeriod$$.asObservable();
  public readonly priceRegex: RegExp = /price/gi;

  public setActivePeriod(period: ActivePeriod[]): void {
    this.activePeriod$$.next(period);
  }

  public scrollToActivePeriod(
    gridApi: GridApi<GridRowData> | undefined,
    colId: string,
    gridResolution: string = '',
  ): void {
    const resolution = this.getResolution(gridResolution);
    let leadingColumnsBeforeActivePeriod = 2;

    if (resolution === '30m') {
      leadingColumnsBeforeActivePeriod = 4;
    }

    if (resolution === '15m') {
      leadingColumnsBeforeActivePeriod = 8;
    }

    const allGridColumns = gridApi?.getAllGridColumns();
    const activePeriodColIndex = allGridColumns?.findIndex((col) => col?.getColId() === colId) ?? 0;
    const firstColumnIndex = activePeriodColIndex - leadingColumnsBeforeActivePeriod;

    if (firstColumnIndex > 0) {
      gridApi?.ensureColumnVisible(allGridColumns?.[firstColumnIndex] ?? '', 'start');
    }
  }

  public updateCellClass(
    gridApi: GridApi<GridRowData> | undefined,
    colId: string,
    gridResolution: string = '',
  ): void {
    const resolutionBlock = this.getResolutionBlock(gridResolution);
    const columnsInResolutionBlock = gridApi?.getColumnDefs()?.filter((col: ColDef) => col.type === 'numericColumn') ?? [];
    const columnsFixed = gridApi?.getColumnDefs()?.filter((col: ColDef) => col.type !== 'numericColumn') ?? [];
    let totalPastCols = 0;

    if (columnsInResolutionBlock?.length === 0) {
      return;
    }

    columnsInResolutionBlock?.map((colDef: ColDef<GridRowData>, index) => {
      const addLeftBorder = index === 0;
      const addRightBorder = (index + 1) % resolutionBlock === 0 && resolutionBlock !== 1;
      const isPastPeriod = DateTime.fromISO(colDef.colId ?? '') < DateTime.fromISO(colId);
      totalPastCols += isPastPeriod ? 1 : 0;

      this.updateHeaderClass(colDef, isPastPeriod, addLeftBorder, addRightBorder);

      colDef.cellClass = (params: CellClassParams<GridRowData>) => {
        const classes = this.cellClassGetter(params, colDef.field ?? '');
        classes.push(...this.buildClassList(isPastPeriod, addLeftBorder, addRightBorder));
        return classes;
      };
      return colDef;
    });

    const granularityMinutes = this.getResolution(gridResolution) === '1h' ? 60 : 0;
    const columnWidth = getColumnWidth(granularityMinutes);
    this.gridChartSyncHelperService.setTotalPastColWidth(gridResolution, totalPastCols * columnWidth);
    gridApi?.updateGridOptions({ columnDefs: [...columnsFixed, ...columnsInResolutionBlock] });
  }

  private getResolutionBlock(gridResolution: string): number {
    // Set to 1 if we don't want to apply the resolution block borders. We only want to apply it to the 15m grid.
    return this.getResolution(gridResolution) === '15m' ? 4 : 1;
  }

  private updateHeaderClass(colDef: ColDef<GridRowData>, isPastPeriod: boolean, addLeftBorder: boolean, addRightBorder: boolean): void {
    if (this.uiStateStore.activeScreenState === 'nop-summary') {
      return;
    }

    let { headerClass } = colDef;

    if (Array.isArray(headerClass)) {
      headerClass = headerClass.filter((className) => className !== 'past-period' && className !== 'cell-border-right');
      headerClass.push(...this.buildClassList(isPastPeriod, addLeftBorder, addRightBorder));
    }

    colDef.headerClass = headerClass;
  }

  private buildClassList(isPastPeriod: boolean, addLeftBorder: boolean, addRightBorder: boolean): string[] {
    const classes = [];

    if (isPastPeriod) {
      classes.push('past-period');
    }

    if (addRightBorder) {
      classes.push('cell-border-right');
    }

    if (addLeftBorder) {
      classes.push('cell-border-left');
    }

    return classes;
  }

  private getResolution(gridResolution: string): GranularityType {
    // gridResolution is only set when on NOP Summary screen. We have multiple grids with different resolutions.
    return gridResolution
      ? this.utilsGranularityService.timeseriesResolutionGranularityMap[gridResolution]
      : this.uiStateStore.granularityState;
  }

  public generateColumns(
    defaultColumnDefinitions: ColDef<GridRowData>[],
    granularity: string,
    interval: Interval,
    tradingTimeOffset = 0,
  ): ColDef<GridRowData>[] {
    const columnsDefs: ColDef<GridRowData>[] = [...defaultColumnDefinitions];
    const intervalMinutes: number = interval.length('minutes');
    const granularityMinutes = this.mapGranularityToMinutes(granularity);
    const numberOfColumns = intervalMinutes / granularityMinutes;

    this.generateColumnPeriods(numberOfColumns, granularityMinutes, tradingTimeOffset, interval.start)
      .forEach(({ periodNumber, colId }) => {
        const columnDefinition = this.buildColumn(colId, granularityMinutes, periodNumber);
        columnsDefs.push(columnDefinition);
      });

    return [...columnsDefs, emptyFillerColumn];
  }

  public mapSnapshotDataToGridData(
    timeSeriesData: TimeseriesSegmentModel[],
    gridData: GridRowData[],
    timeZone: string,
  ): void {
    timeSeriesData.forEach((segment) => {
      const rowData = gridData.find((x) => x.name === segment.rowName);
      if (rowData != null) {
        rowData.ownerId = segment.ownerId;
        rowData.timeSeriesType = segment.timeSeriesType;
        this.mapValueToTimeSlot(segment.values, timeZone).forEach((x) => {
          rowData[x.slot] = x.value;
        });
      }
    });
  }

  public mapDeltaUpdatesToGridData(
    timeSeriesData: TimeseriesSegmentModel[],
    existingGridData: GridRowData[],
    timeZone: string,
  ): GridRowData[] {
    const updatedGridData: GridRowData[] = [];
    timeSeriesData.forEach((segment) => {
      const updatedGridDataRow: GridRowData = {
        name: segment.rowName,
        ownerId: segment.ownerId,
        timeSeriesType: segment.timeSeriesType,
      };
      const rowData = existingGridData.find((x) => x.name === segment.rowName);
      let addRowToUpdateData = false;

      if (rowData != null) {
        if (rowData.unit) {
          updatedGridDataRow.unit = rowData.unit;
          addRowToUpdateData = true;
        }
        rowData.ownerId = segment.ownerId;
        rowData.timeSeriesType = segment.timeSeriesType;
        updatedGridDataRow.ownerId = segment.ownerId;
        updatedGridDataRow.timeSeriesType = segment.timeSeriesType;
        this.mapValueToTimeSlot(segment.values, timeZone).forEach((x) => {
          addRowToUpdateData = true;
          updatedGridDataRow[x.slot] = x.value;
          rowData[x.slot] = x.value;
        });
      }

      if (addRowToUpdateData) {
        updatedGridData.push(updatedGridDataRow);
      }
    });

    return updatedGridData;
  }

  public mapDeltaUpdatesToMfrrGridData(
    timeSeriesData: TimeseriesSegmentModel[],
    existingGridData: GridRowData[],
    timeZone: string,
  ): GridRowData[] {
    const updatedGridData: GridRowData[] = [];

    existingGridData.forEach((gridRow) => {
      if (!gridRow.unit || gridRow.name as TimeSeriesSegmentTypeEnum === TimeSeriesSegmentTypeEnum.StartTime) {
        return;
      }

      const updatedGridDataRow: GridRowData = {
        name: gridRow.name, unit: gridRow.unit, ownerId: gridRow.ownerId, timeSeriesType: gridRow.timeSeriesType,
      };
      const timeseriesRow = timeSeriesData.find((ts) => ts.rowName === gridRow.name);

      if (timeseriesRow) {
        updatedGridDataRow.ownerId = timeseriesRow.ownerId;
        updatedGridDataRow.timeSeriesType = timeseriesRow.timeSeriesType;

        this.mapValueToTimeSlot(timeseriesRow.values, timeZone).forEach((x) => {
          updatedGridDataRow[x.slot] = x.value;
          gridRow[x.slot] = x.value;
        });
      } else {
        Object.keys(gridRow).forEach((key) => {
          if (key !== 'name' && key !== 'unit' && key !== 'ownerId' && key !== 'timeSeriesType') {
            delete gridRow[key];
          }
        });
      }

      updatedGridData.push(updatedGridDataRow);
    });

    return updatedGridData;
  }

  public generateStartTimeRowData(
    granularity: string,
    columnsDef: ColDef[],
    startOfDay: DateTime,
    tradingTimeOffset = 0,
  ): { [key: string]: string } {
    const granularityMinutes = this.mapGranularityToMinutes(granularity);
    const rowValues: { [key: string]: string } = {};
    const colPeriodCount = columnsDef.filter(
      (col) => col.type === 'numericColumn' && col.field !== fillerColumnFieldName,
    )?.length || 0;

    this.generateColumnPeriods(colPeriodCount, granularityMinutes, tradingTimeOffset, startOfDay)
      .forEach(({ colId, startTime, isLongDayDuplicatePeriod }) => {
        rowValues[colId] = isLongDayDuplicatePeriod ? `${startTime.toFormat('HH:mm')}X` : startTime.toFormat('HH:mm');
      });

    return rowValues;
  }

  public generateChartColumns(granularity: string, interval: Interval, tradingTimeOffset = 0): GridColumnPeriod[] {
    const intervalMinutes: number = interval.length('minutes');
    const granularityMinutes = this.mapGranularityToMinutes(granularity);
    const numberOfPoints = intervalMinutes / granularityMinutes;

    return this.generateColumnPeriods(numberOfPoints, granularityMinutes, tradingTimeOffset, interval.start);
  }

  public getChartData(
    timeseriesValues: TimeseriesValueModel[],
    chartColumns: GridColumnPeriod[],
    timeZoneState: string,
  ): NullableNumberArray {
    const timeSlots = this.mapValueToTimeSlot(timeseriesValues, timeZoneState);

    const chartTimeSeriesData: NullableNumberArray = [];
    chartColumns.forEach(({ colId }) => {
      const snapshotTimeSlot = timeSlots.find((x) => x.slot === colId);
      chartTimeSeriesData.push(snapshotTimeSlot?.value.numericValue.toNumber() ?? null);
    });

    return chartTimeSeriesData;
  }

  public adjustNumber(value: number, decimals: number): number {
    if (Number.isInteger(value)) {
      return value;
    }

    return Big(value).round(decimals, Big.roundDown).toNumber();
  }

  private buildColumn(field: string, granularityMinutes: number, periodNumber: number): ColDef<GridRowData> {
    const headerName = `${this.periodPrefixFromGranularity(granularityMinutes)}${periodNumber}`;
    const columnWidth = getColumnWidth(granularityMinutes);

    return {
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      sortable: false,
      headerName,
      field,
      type: 'numericColumn',
      headerClass: ['ag-grid-time-series-value-cell'],
      cellClass: (params: CellClassParams<GridRowData>) => this.cellClassGetter(params, field),
      editable: false,
      cellEditor: 'cellEditor',
      enableCellChangeFlash: true,
      width: columnWidth,
      initialWidth: columnWidth,
      minWidth: columnWidth,
      valueGetter: (params: ValueGetterParams<GridRowData>) => this.valueGetter(params, field),
      valueSetter: (params: ValueSetterParams<GridRowData>) => this.valueSetter(params, field),
      valueParser: (params: ValueParserParams<GridRowData>) => this.valueParser(params),
    };
  }

  private mapValueToTimeSlot(
    timeseriesValues: TimeseriesValueModel[],
    timeZone: string,
  ): { slot: string; value: TimeseriesValueModel }[] {
    return timeseriesValues.map<{ slot: string; value: TimeseriesValueModel }>((value) => ({
      slot: value.start.setZone(timeZone).toISO(),
      value,
    }));
  }

  public mapGranularityToMinutes(granularity: string): number {
    let granularityMinutes;
    switch (granularity) {
      case '1h':
        granularityMinutes = 60;
        break;
      case '15m':
        granularityMinutes = 15;
        break;
      case '30m':
        granularityMinutes = 30;
        break;
      default:
        throw Error(`Unexpected granularity:${granularity}`);
    }
    return granularityMinutes;
  }

  public combineAndMapUTCSegments(segments: TimeSeriesSegment[]): List<TimeseriesSegmentModel> {
    const mappedSegments: TimeseriesSegmentModel[] = [];

    segments.forEach((segment) => {
      const foundSegment = mappedSegments.find((s) => (
        s.timeSeriesType === segment.timeSeriesType
        && s.ownerType === segment.ownerType
        && s.ownerId === segment.ownerId
      ));

      if (foundSegment) {
        foundSegment.values = foundSegment.values.concat(segment.values.map((v) => this.mapTimeseriesValueDates(segment, v)));
      } else {
        mappedSegments.push(this.mapTimeseriesSegment(segment));
      }
    });

    return List(mappedSegments);
  }

  public mapTimeseriesSegment(segment: TimeSeriesSegment): TimeseriesSegmentModel {
    return {
      rowName: segment.timeSeriesType,
      timeSeriesType: segment.timeSeriesType,
      ownerType: segment.ownerType,
      ownerId: segment.ownerId,
      values: segment.values.map((v) => this.mapTimeseriesValueDates(segment, v)),
    };
  }

  private mapTimeseriesValueDates(segment: TimeSeriesSegment, value: TimeSeriesValue): TimeseriesValueModel {
    const start = DateTime.fromISO(value.start);

    return {
      utcDate: segment.utcDate,
      start,
      end: DateTime.fromISO(value.end),
      numericValue: Big(value.numericValue),
      isDifferent: value.isDifferent,
      isOverridden: value.isOverridden,
    };
  }

  public cellClassGetter(params: CellClassParams<GridRowData>, index: string): string[] {
    const cellClasses: string[] = ['ag-grid-time-series-value-cell'];
    const cellValue = params.data?.[index];

    if (!cellValue || typeof cellValue === 'string') {
      return cellClasses;
    }

    cellClasses.push('has-value');

    if (params.data?.timeSeriesType === TimeSeriesSegmentTypeEnum.ExtProdPlan) {
      const rowNodeProdPlan = params.api.getRowNode('Prod Plan')?.data?.[index] as TimeseriesValueModel;

      if (!rowNodeProdPlan?.numericValue.eq(cellValue.numericValue)) {
        cellClasses.push('tier-highlight');
      }
    }

    if (cellValue.isDifferent) {
      cellClasses.push('different');
    }

    if (cellValue.isOverridden) {
      cellClasses.push('overridden');
    }

    if (params.api.getColumn(index)?.isCellEditable(params.node)) {
      cellClasses.push('editable');
    }

    return cellClasses;
  }

  public valueGetter(params: ValueGetterParams<GridRowData>, index: string): string | number | undefined {
    const paramsData = params.data;
    const cellValue = paramsData?.[index];

    if (!cellValue || typeof cellValue === 'string') {
      return cellValue;
    }

    const decimalPlaces = paramsData.name.match(this.priceRegex) ? 2 : 1;
    return cellValue.numericValue.round(decimalPlaces, Big.roundDown).toNumber();
  }

  private valueSetter(params: ValueSetterParams<GridRowData>, index: string): boolean {
    const paramsData = (params.data as Record<string, TimeseriesValueModel>);
    const numericValue = Number(params.newValue);

    if (!Number.isNaN(numericValue) && !paramsData[index].numericValue.eq(numericValue)) {
      paramsData[index].numericValue = Big(numericValue);
      paramsData[index].isOverridden = true;
      paramsData[index].isDifferent = false;
      params.api.flashCells({
        rowNodes: [params.node as RowNode],
        columns: [params.column],
      });
      return true;
    }

    return false;
  }

  private valueParser(params: ValueParserParams<GridRowData>): number {
    const decimalPlaces = params.data.name.match(this.priceRegex) ? 2 : 1;
    return this.adjustNumber(Number(params.newValue), decimalPlaces);
  }

  public periodPrefixFromGranularity(granularityMinutes: number): string {
    switch (granularityMinutes) {
      case 15:
        return 'qh';
      case 30:
        return 'hh';
      default:
        return '';
    }
  }

  public generateColumnPeriods(
    columnCount: number,
    granularityMinutes: number,
    tradingTimeOffset: number,
    start: DateTime,
  ): GridColumnPeriod[] {
    const offsetPeriods = tradingTimeOffset / granularityMinutes;
    const startWithOffset = start.plus({ minutes: tradingTimeOffset });
    let startPeriodIndex = 0 + offsetPeriods;
    let prevDayColumnCount = columnCount;

    if (offsetPeriods < 0) {
      // The offset shifts to the previous day so it might have different period count
      const prevDayMinutes = Interval.fromDateTimes(start.minus({ day: 1 }), start).length('minutes');
      prevDayColumnCount = prevDayMinutes / granularityMinutes;
      startPeriodIndex = prevDayColumnCount + offsetPeriods;
    }

    let periodIndex = startPeriodIndex;

    const periods = Array.from({ length: columnCount }, (_, i) => {
      if (periodIndex > prevDayColumnCount - 1) {
        periodIndex = 0;
      }

      const startTime = startWithOffset.plus({ minutes: i * granularityMinutes });
      const item = {
        periodNumber: periodIndex + 1,
        colId: startTime.toISO(),
        startTime,
        isLongDayDuplicatePeriod: false,
      };
      periodIndex++;

      return item;
    });

    return this.longDayPeriodAdjust(periods);
  }

  public longDayPeriodAdjust(periods: GridColumnPeriod[]): GridColumnPeriod[] {
    if (periods.every((p) => p.startTime.isInDST) || periods.every((p) => !p.startTime.isInDST)) {
      return periods;
    }

    return periods.map((currentPeriod, index) => {
      const prevDuplicatePeriod = periods.find((p, index2) => (
        index2 < index
        && p.startTime.hour === currentPeriod.startTime.hour
        && p.startTime.minute === currentPeriod.startTime.minute
      ));

      return {
        ...currentPeriod,
        isLongDayDuplicatePeriod: !!prevDuplicatePeriod,
        periodNumber: currentPeriod.periodNumber,
      };
    });
  }
}
