import { Injectable } from '@angular/core';

import { HierarchyType } from '@bradyplc/brady.powerdesk.api.internal.setpoints.contracts';
import {
  AssetGroupResult,
  AssetResult,
  GeneratorResult,
} from '@bradyplc/brady.powerdesk.api.internal.systemreferencedata.contracts';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PortfolioHierarchyFacadeService } from '../../portfolio-hierarchy/services/portfolio-hierarchy-facade.service';
import { LastSelectedAssetHierarchy } from '../models/last-selected-asset-hierarchy.model';
import { SelectableAssetGroupModel } from '../models/selectable-asset-group.model';
import { SelectableAssetModel } from '../models/selectable-asset.model';
import { SelectableGeneratorModel } from '../models/selectable-generator.model';

import { AssetHierarchyApiService } from './asset-hierarchy-api.service';

@Injectable({
  providedIn: 'root',
})
export class AssetHierarchyFacadeService {
  private norwegianLocale = 'no';

  private selectedAssetHierarchy$$ = new BehaviorSubject<SelectableAssetGroupModel[]>([]);
  public selectedAssetHierarchy$ = this.selectedAssetHierarchy$$.asObservable();

  private selectedPortfolioAssetCount$$ = new BehaviorSubject<number>(0);
  public selectedPortfolioAssetCount$ = this.selectedPortfolioAssetCount$$.asObservable();

  constructor(
    private assetHierarchyApiService: AssetHierarchyApiService,
    private portfolioHierarchyFacadeService: PortfolioHierarchyFacadeService,
  ) {
    this.portfolioHierarchyFacadeService.selectedPortfolio$.subscribe(
      (selectedPortfolioId) => {
        if (
          selectedPortfolioId
          && !this.portfolioHierarchyFacadeService
            .isFirstSelectedPortfolioHierarchy
        ) {
          this.assetHierarchyApiService
            .getAssetHierarchy$(selectedPortfolioId)
            .subscribe((assetGroups: AssetGroupResult[]) => {
              const assetCount = assetGroups.reduce((count, group) => count + group.assets.length, 0);
              this.selectedPortfolioAssetCount$$.next(assetCount);
              this.mapToSelectableModelsWithDefaultSelection(assetGroups);
            });
        }
      },
    );
  }

  public get selectedAssetHierarchy(): SelectableAssetGroupModel[] {
    return this.selectedAssetHierarchy$$.value;
  }

  public get getSelectedGenerator(): SelectableGeneratorModel | undefined {
    const selectedAssetGroup = this.selectedAssetHierarchy$$.value.find((s) => s.isSelected);
    const selectedAsset = selectedAssetGroup?.assets.find((s) => s.isSelected);
    return selectedAsset?.generators.find((g) => g.isSelected);
  }

  public get getSelectedGenerator$(): Observable<SelectableGeneratorModel | undefined> {
    return this.selectedAssetHierarchy$.pipe(
      map(() => this.getSelectedGenerator),
    );
  }

  public get getSelectedAssetGroup(): SelectableAssetGroupModel | undefined {
    return this.selectedAssetHierarchy$$.value.find((s) => s.isSelected);
  }

  public get getSelectedAssetGroup$(): Observable<SelectableAssetGroupModel | undefined> {
    return this.selectedAssetHierarchy$.pipe(map(() => this.getSelectedAssetGroup));
  }

  public setSelectedAssetHierarchy(state: SelectableAssetGroupModel[]): void {
    this.selectedAssetHierarchy$$.next(state);
  }

  public get lastSelectedItemInTheHierarchy(): LastSelectedAssetHierarchy | undefined {
    const selectedAssetGroup = this.selectedAssetHierarchy$$.value.find((s) => s.isSelected);
    const selectedAsset = selectedAssetGroup?.assets.find((s) => s.isSelected);
    const selectedGenerator = selectedAsset?.generators.find((g) => g.isSelected);

    if (selectedGenerator) {
      return { id: selectedGenerator.id, hierarchyType: HierarchyType.Generator, name: selectedGenerator.name };
    }

    if (selectedAsset) {
      return { id: selectedAsset.id, hierarchyType: HierarchyType.Asset, name: selectedAsset.name };
    }

    if (selectedAssetGroup) {
      return { id: selectedAssetGroup.id, hierarchyType: HierarchyType.AssetGroup, name: selectedAssetGroup.name };
    }

    return undefined;
  }

  public get lastSelectedItemInTheHierarchy$(): Observable<LastSelectedAssetHierarchy | undefined> {
    return this.selectedAssetHierarchy$.pipe(
      map(() => this.lastSelectedItemInTheHierarchy),
    );
  }

  private mapToSelectableModelsWithDefaultSelection(
    assetGroups: AssetGroupResult[],
  ) {
    // Compare in Norwegian locale with string names (compare numbers as strings), e.g.:  Holen 12, Holen 3,
    const compareNames = (a: { name?: string }, b: { name?: string }): number => (a.name ?? '').localeCompare(b.name ?? '', this.norwegianLocale);

    assetGroups.sort(compareNames);
    const selectableAssetGroups: SelectableAssetGroupModel[] = assetGroups.map(
      (assetGroup: AssetGroupResult) => {
        assetGroup.assets.sort(compareNames);
        const selectableAssetGroup: SelectableAssetGroupModel = {
          id: assetGroup.id,
          name: assetGroup.name,
          isSelected: false,
          assets: assetGroup.assets.map((asset: AssetResult) => {
            asset.generators.sort(compareNames);
            const selectableAsset: SelectableAssetModel = {
              id: asset.id,
              name: asset.name,
              isSelected: false,
              generators: asset.generators.map(
                (generator: GeneratorResult) => {
                  const selectableGenerator: SelectableGeneratorModel = {
                    id: generator.id,
                    name: generator.name,
                    isSelected: false,
                  };

                  return selectableGenerator;
                },
              ),
            };

            return selectableAsset;
          }),
        };

        return selectableAssetGroup;
      },
    );

    this.selectedAssetHierarchy$$.next(selectableAssetGroups);
  }
}
