import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';

import {
  IANAZone,
  Settings,
} from 'luxon';
import {
  INGXLoggerConfig,
  NGXLogger,
} from 'ngx-logger';
import {
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';

import { LoggedUserService } from './core/services/logged-user.service';
import { TelemetryService } from './core/services/telemetry.service';
import { UIStateStore } from './core/state-store/ui-state-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private uiStateStore: UIStateStore,
    private telemetryService: TelemetryService,
    private loggedUserServise: LoggedUserService,
    private logger: NGXLogger,
  ) {}

  public ngOnInit(): void {
    this.loggedUserServise.loggedUser$.pipe(filter((x) => !!x?.id), distinctUntilChanged()).subscribe((loggedUser) => {
      this.telemetryService.setUserId(loggedUser.id);
    });

    this.uiStateStore.timeZoneState$.subscribe((timezone) => { Settings.defaultZone = IANAZone.create(timezone) });

    this.exposeGlobalLoggerConfig();
  }

  private exposeGlobalLoggerConfig(): void {
    (window as (
      Window
      & typeof globalThis
      & { setLoggerConfig: (config: INGXLoggerConfig) => void }
    )).setLoggerConfig = (config: Partial<INGXLoggerConfig>): void => {
      this.logger.updateConfig({
        ...this.logger.getConfigSnapshot(),
        ...config,
      });
    };
  }
}
