<div class="app-bar">
  <img src="/assets/img/brady_logo.svg" alt="Brady logo" class="app-bar__logo"/>

  <div class="app-bar__info">
    <div data-testid="app-bar-date">{{ date$ | async }}</div>
    <div>|</div>
    <app-clock></app-clock>
    <div>|</div>

    <app-avatar [photoUrl]="avatarPhotoUrl" [initials]="avatarInitials">
      <button mat-menu-item [attr.data-testid]="'button-profile-settings'" routerLink="/settings">Settings</button>
      <button mat-menu-item [attr.data-testid]="'button-profile-sign-out'" (click)="onClickSignOut()">Sign out</button>
    </app-avatar>

    @if ((unseenNotificationsCount$ | async) === 0) {
      <button
        matRipple
        class="menu"
        [class.menu--active]="inboxPanel.showPanel"
        (click)="onClickInbox()"
        data-testid="appBar-button-menu"
        >
        <mat-icon class="menu__icon">menu</mat-icon>
      </button>
    } @else {
      <div
        class="menu menu--with-notification"
        data-testid="appBar-button-menu"
        (keydown.enter)="onClickInbox()"
        (click)="onClickInbox()"
        [attr.tabindex]= "0">
          <mat-icon
            matBadgeSize="medium"
            aria-hidden="false"
            [matBadge]="unseenNotificationsCount$ | async"
            matBadgeOverlap="true"
            class="menu__icon">
            menu
          </mat-icon>
      </div>
    }

  </div>
</div>
