import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDeniedComponent implements OnInit {
  constructor(private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.renderer.removeClass(document.body, 'theme-light');
    this.renderer.addClass(document.body, 'theme-dark');
  }
}
