<div class="action-bar">
  <app-portfolio-hierarchy-container
    class="action-bar-left"
  ></app-portfolio-hierarchy-container>

  <div class="action-bar-center">
    @if ((uiStateStore.activeScreenState$ | async) !== 'nop-summary') {
      <mat-button-toggle-group
        name="timePeriod"
        [hideSingleSelectionIndicator]="true"
        [value]="uiStateStore.granularityState$ | async"
        class="actions-buttons"
        >
        @for (timePeriod of (timePeriods$ | async); track timePeriod) {
          <mat-button-toggle
            [value]="timePeriod.granularity"
            (change)="onChangeUpdateUIGranularityStore($event.value)"
            >
            {{ timePeriod.displayValue }}
          </mat-button-toggle>
        }
      </mat-button-toggle-group>
    }

    <mat-button-toggle-group
      name="datePeriod"
      [hideSingleSelectionIndicator]="true"
      [value]="uiStateStore.periodState$ | async"
      class="actions-buttons"
      >
      @for (period of datePeriods; track period) {
        <mat-button-toggle
          [attr.data-testid]="'mat-button-toggle-' + toLowerCase(period)"
          [value]="period"
          (change)="onChangeUpdateUIPeriodStore($event.value)"
          >
          {{ period }}
        </mat-button-toggle>
      }
    </mat-button-toggle-group>
  </div>

  <div class="action-bar-right">
    @if (deactivateOrdersButtonVisible) {
      <app-deactivate-orders-button></app-deactivate-orders-button>
    }

    @if (editSetPointsVisible) {
      <div class="action-bar-set-points">
        <mat-slide-toggle
          color="primary"
          [checked]="setPointsFacade.showPanel$ | async"
          [disabled]="!editSetPointsEnabled"
          (toggleChange)="onToggleSetPointsHandler()"> Edit Set Points
        </mat-slide-toggle>
      </div>
    }

    @if (editSetPointsVisible) {
      <div class="action-bar-submit-tso">
        <button
          mat-flat-button
          color="primary"
          [disabled]="!editSetPointsEnabled || submittingToTSO"
          (click)="onSubmitToTSO()">SUBMIT TO TSO
        </button>
      </div>
    }
  </div>
</div>
