import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { ErrorDialogDataModel } from '../models/error-dialog-data.model';
import { ErrorDialogResultModel } from '../models/error-dialog-result.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  /**
   * This CSS class is used to set custom styles on material dialog
   */
  private dialogPanelClass = 'error-dialog-panel';

  constructor(public dialog: MatDialog) {
  }

  public showErrorDialog(data: ErrorDialogDataModel): Observable<ErrorDialogResultModel | undefined> {
    return this.dialog.open<ErrorDialogComponent, ErrorDialogDataModel, ErrorDialogResultModel>(ErrorDialogComponent, {
      data,
      panelClass: this.dialogPanelClass,
    }).afterClosed().pipe(take(1));
  }
}
