import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() photoUrl?: string;
  @Input() initials?: string;
  @Input() parent?: string;

  public get showInitials(): boolean {
    return !this.photoUrl;
  }
}
