import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { List } from 'immutable';
import { DateTime } from 'luxon';
import { NGXLogger } from 'ngx-logger';
import {
  combineLatest,
  from,
  lastValueFrom,
  of,
  Subscription,
  timer,
} from 'rxjs';
import {
  catchError,
  exhaustMap,
  pairwise,
  retry,
  RetryConfig,
  startWith,
} from 'rxjs/operators';

import { SelectableAssetGroupModel } from '../../modules/asset-hierarchy/models/selectable-asset-group.model';
import { AssetHierarchyFacadeService } from '../../modules/asset-hierarchy/services/asset-hierarchy-facade.service';
import { SelectedPortfolioHierarchyModel } from '../../modules/portfolio-hierarchy/models/selected-portfolio-hierarchy.model';
import { PortfolioHierarchyFacadeService } from '../../modules/portfolio-hierarchy/services/portfolio-hierarchy-facade.service';
import { genericRetryStrategy } from '../rxjs/generic-retry-strategy';
import { TimeSeriesHelperService } from '../services/time-series-helper.service';
import { UtilsGranularityService } from '../services/utils/utils-granularity.service';
import { GranularityType } from '../state-store/models/granularity.model';
import { PeriodType } from '../state-store/models/period.model';
import { UIStateStore } from '../state-store/ui-state-store.service';

import { FeedsApiService } from './feeds-api.service';
import { FeedsService } from './feeds.service';
import { ConnectionHandlersService } from './handlers/connection-handlers.service';
import { HeartbeatHandlerService } from './handlers/heartbeat-handler.service';
import { SetPointsHandlerService } from './handlers/set-points-handler.service';
import { UpdateHandlerService } from './handlers/update-handler.service';
import { Feed, NotificationResponse } from './models/feed';
import { SignalrStatusTypeEnum } from './models/signalr-status-type.enum';
import { SignalRService } from './signal-r.service';

type CombinedStateTuple = [
  SelectedPortfolioHierarchyModel | null,
  GranularityType,
  PeriodType,
  SelectableAssetGroupModel | undefined,
];

@Injectable({
  providedIn: 'root',
})
export class PeriodicHeathCheckService implements OnDestroy {
  private readonly heartbeatReceiveThresholdMS = 30000;
  private loadSize = 100;
  private readonly timer = timer(1, 5000);
  private readonly subscriptions = new Subscription();
  private portfolioSubscription = new Subscription();

  constructor(
    private ngZone: NgZone,
    private portfolioHierarchyFacade: PortfolioHierarchyFacadeService,
    private uiStateService: UIStateStore,
    private feedsApiService: FeedsApiService,
    private feedsService: FeedsService,
    private heartbeatHandler: HeartbeatHandlerService,
    private updateHandlerService: UpdateHandlerService,
    private connectionHandlersService: ConnectionHandlersService,
    private signalRService: SignalRService,
    private setPointsHandlerService: SetPointsHandlerService,
    private assetHierarchyFacadeService: AssetHierarchyFacadeService,
    private logger: NGXLogger,
    private timeseriesHelper: TimeSeriesHelperService,
    private utilsGranularity: UtilsGranularityService,
  ) {
  }

  public ngOnDestroy(): void {
    this.cleanUpSubscriptions();
  }

  public initialize(): void {
    const retryConfig: RetryConfig = {
      delay: genericRetryStrategy({
        scalingDuration: 500,
        maxRetryAttempts: 2,
      }),
    };

    this.subscriptions.add(
      this.timer.pipe(
        exhaustMap(() => from(this.onTimer()).pipe(
          retry(retryConfig),
          catchError(() => of()),
        )),
      ).subscribe(),
    );
  }

  public cleanUpSubscriptions(): void {
    this.subscriptions.unsubscribe();
  }

  private async onTimer(): Promise<void> {
    await this.ngZone.runOutsideAngular(async () => {
      if (this.signalRService.status === SignalrStatusTypeEnum.Disconnected) {
        await this.start();

        return;
      }

      const shouldDisconnect = await this.shouldDisconnect();
      if (shouldDisconnect) {
        await this.signalRService.disconnect();
      }
    });
  }

  private async start(): Promise<void> {
    await this.signalRService.initializeSignalRConnection();

    // Add SignalR event handlers
    this.updateHandlerService.initialize();
    this.heartbeatHandler.initialize();
    this.connectionHandlersService.initialize();
    this.setPointsHandlerService.initialize();

    // Start SignalR connection and obtain connection id
    const connectionId = await this.signalRService.connect();
    this.feedsApiService.setSignalrConnectionId(connectionId);

    // Subscribe to shared feeds
    if (this.feedsService.mandatoryFeedIds.length > 1) {
      await lastValueFrom(this.feedsApiService.deleteUserSubscriptions$(this.feedsService.mandatoryFeedIds));
    }
    const requestedFeeds = await lastValueFrom(this.feedsApiService.addUserToSubscriptions$([]));
    this.feedsService.mandatoryFeedIds = requestedFeeds.feeds;

    requestedFeeds.feeds.forEach((requestedFeed) => {
      const feed = new Feed(requestedFeed);
      this.feedsService.addFeed(feed);
      void this.reloadSnapshot(feed, 100);
    });

    this.portfolioSubscription.unsubscribe();
    this.portfolioSubscription = new Subscription();
    this.portfolioSubscription.add(
      combineLatest([
        this.portfolioHierarchyFacade.selectedPortfolioHierarchy$,
        this.uiStateService.granularityState$,
        this.uiStateService.periodState$,
        this.assetHierarchyFacadeService.getSelectedAssetGroup$,
      ]).pipe(
        startWith(null),
        pairwise(),
      ).subscribe(([prevState, currState]) => {
        this.deleteSubscribtionsAndRemoveFeeds(prevState, currState)
          .then(() => this.addSubscribtionsAndFeeds(prevState, currState))
          .catch((error) => this.logger.error('An error occured while re-subscribing to feeds', error));
      }),
    );
  }

  private async shouldDisconnect(): Promise<boolean> {
    let needToDisconnect = false;

    const promises = this.feedsService.feeds.map<Promise<void>>((feed) => {
      if (!feed.heartbeatTimestamp) {
        this.logger.debug(`[onTimer] [${feed.id}] No heartbeat received yet.`);
        feed.status = 'No heartbeat received yet';
        this.updateFeed(feed);

        return Promise.resolve();
      }

      if (DateTime.now().toMillis() - feed.heartbeatTimestamp > this.heartbeatReceiveThresholdMS) {
        this.logger.debug(`[onTimer] [${feed.id}] No heartbeat for ${this.heartbeatReceiveThresholdMS} ms - disconnecting.`);
        feed.status = `No heartbeat for ${this.heartbeatReceiveThresholdMS} ms - disconnecting`;
        needToDisconnect = true;
        this.updateFeed(feed);

        return Promise.resolve();
      }

      if (feed.sequenceNumber === -1) {
        this.logger.debug(`[onTimer] [${feed.id}] Loading latest snapshot.`);
        feed.status = `Loading latest snapshot`;
        return this.reloadSnapshot(feed, this.loadSize);
      }

      // Heartbeat is ahead of the feed sequence number
      if (feed.heartbeatAheadTimestamp && DateTime.now().toMillis() - feed.heartbeatAheadTimestamp > this.heartbeatReceiveThresholdMS) {
        this.logger.debug(
          `[onTimer] [${feed.id}] Heartbeat ahead for ${this.heartbeatReceiveThresholdMS} ms - reloading snapshot.`,
        );
        feed.status = `Heartbeat ahead for ${this.heartbeatReceiveThresholdMS} ms - reloading snapshot`;
        needToDisconnect = true;
        this.updateFeed(feed);

        return Promise.resolve();
      }

      feed.status = `Connected`;
      this.updateFeed(feed);
      return Promise.resolve();
    });

    return Promise.all(promises).then(() => needToDisconnect);
  }

  private updateFeed(feed: Feed) {
    this.feedsService.updateFeed(feed);
  }

  private async reloadSnapshot(feed: Feed, maxSize: number): Promise<void> {
    const snapshot = await lastValueFrom(this.feedsApiService.loadSnapshot$(feed.id, maxSize));
    if (!snapshot) {
      this.logger.debug(`[loadSnapshot] [${feed.id}] No snapshot.`);
      return;
    }

    if (feed.buffer.isBufferAhead(snapshot.sequenceNumber)) {
      this.logger.debug(
        `[loadSnapshot] [${feed.id}] Buffer (${feed.buffer.getSequenceNumber()}) ahead of snapshot (${
          snapshot.sequenceNumber
        }).`,
      );
      return;
    }

    this.logger.debug(`[loadSnapshot] [${feed.id}] Applying snapshot with sequenceNumber (${snapshot.sequenceNumber}).`, snapshot);

    feed.sequenceNumber = snapshot.sequenceNumber;
    feed.data.notifications = List(snapshot.notifications as NotificationResponse[]);
    feed.data.acknowledgments = List(snapshot.acknowledgments);
    feed.data.contracts = List(snapshot.contracts);
    feed.data.marketDepth = snapshot.marketDepth[0];
    feed.data.timeseriesSegments = this.timeseriesHelper.combineAndMapUTCSegments(snapshot.timeseriesSegments);

    const isBufferApplied = this.feedsService.applyBufferedUpdates(feed);
    if (!isBufferApplied) {
      this.feedsService.updateFeed(feed);
    }
  }

  private async deleteSubscribtionsAndRemoveFeeds(
    prevState: CombinedStateTuple | null,
    nextState: CombinedStateTuple | null,
  ): Promise<void> {
    if (!prevState) {
      return;
    }

    const [prevPortfolio, prevGranularity, , prevAssetGroup] = prevState;
    const nextPortfolio = nextState?.[0];
    const nextGranularity = nextState?.[1];
    const nextAssetGroup = nextState?.[3];
    const prevFeedIds: string[] = [];

    if (prevPortfolio) {
      const { deliveryAreaId, participantId, portfolioId } = prevPortfolio as Required<SelectedPortfolioHierarchyModel>;

      if (
        this.uiStateService.activeScreenState !== 'nop-summary'
        && (portfolioId !== nextPortfolio?.portfolioId || nextGranularity !== prevGranularity)
      ) {
        const granularityMinutes = this.utilsGranularity.convertGranularityTypeToText(prevGranularity);
        prevFeedIds.push(`${participantId}|timeseries-portfolio-${portfolioId}-${granularityMinutes}`);
      }

      if (deliveryAreaId !== nextPortfolio?.deliveryAreaId) {
        prevFeedIds.push(`contracts-${deliveryAreaId}`);
      }
    }

    if (prevAssetGroup && prevAssetGroup.id !== nextAssetGroup?.id) {
      prevFeedIds.push(prevAssetGroup.id);
    }

    if (prevFeedIds.length > 0) {
      await lastValueFrom(this.feedsApiService.deleteUserSubscriptions$(prevFeedIds));
      prevFeedIds.forEach((prevFeedName) => this.feedsService.updateFeed(new Feed(prevFeedName)));
    }
  }

  private async addSubscribtionsAndFeeds(
    prevState: CombinedStateTuple | null,
    nextState: CombinedStateTuple | null,
  ): Promise<void> {
    if (!nextState) {
      return;
    }

    const prevPortfolio = prevState?.[0];
    const prevGranularity = prevState?.[1];
    const prevPeriod = prevState?.[2];
    const prevAssetGroup = prevState?.[3];
    const [nextPortfolio, nextGranularity, nextPeriod, nextAssetGroup] = nextState;
    const nextSubIds: string[] = [];
    const nextSnapshotIds = new Set<string>();

    if (nextPortfolio) {
      const { deliveryAreaId, participantId, portfolioId } = nextPortfolio as Required<SelectedPortfolioHierarchyModel>;
      const granularityMinutes = this.utilsGranularity.convertGranularityTypeToText(nextGranularity);
      const timeseriesFeedId = `${participantId}|timeseries-portfolio-${portfolioId}-${granularityMinutes}`;
      const contractsFeedId = `contracts-${deliveryAreaId}`;

      if (nextPeriod !== prevPeriod) {
        nextSnapshotIds.add(timeseriesFeedId);
        nextSnapshotIds.add(contractsFeedId);
      }

      if (portfolioId !== prevPortfolio?.portfolioId || nextGranularity !== prevGranularity) {
        nextSubIds.push(timeseriesFeedId);
        nextSnapshotIds.add(timeseriesFeedId);
      }

      if (deliveryAreaId !== prevPortfolio?.deliveryAreaId) {
        nextSubIds.push(contractsFeedId);
        nextSnapshotIds.add(contractsFeedId);
      }

      if (this.shouldSkipTimeseriesSnapshot(timeseriesFeedId)) {
        nextSnapshotIds.delete(timeseriesFeedId);
      }
    }

    if (nextAssetGroup && nextAssetGroup.id !== prevAssetGroup?.id) {
      nextSubIds.push(nextAssetGroup.id);
    }

    if (nextSubIds.length > 0) {
      await lastValueFrom(this.feedsApiService.addUserToSubscriptions$(nextSubIds));
      nextSubIds.forEach((feedId) => this.feedsService.addFeed(new Feed(feedId)));
    }

    nextSnapshotIds.forEach((currFeedName) => {
      const feed = this.feedsService.getFeedById(currFeedName) || new Feed(currFeedName);
      this.feedsService.addFeed(feed);
      void this.reloadSnapshot(feed, this.loadSize);
    });
  }

  private shouldSkipTimeseriesSnapshot(timeseriesFeedId: string): boolean {
    return this.uiStateService.activeScreenState === 'nop-summary'
      && (this.feedsService.getFeedById(timeseriesFeedId)?.sequenceNumber ?? -1) !== -1;
  }
}
