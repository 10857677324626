import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  OrderManagementCommand,
  OrderManagementType,
} from '@bradyplc/brady.powerdesk.api.internal.intraday.contracts';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageOrdersApiService {
  constructor(private httpClient: HttpClient) {
  }

  public manageOrders$(commandType: OrderManagementType, participantId: string): Observable<void> {
    const requestBody: OrderManagementCommand = {
      commandType,
    };

    return this.httpClient.post<void>(environment.manageOrdersUrl, requestBody, {
      headers: {
        ParticipantId: participantId,
      },
    });
  }
}
