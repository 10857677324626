<button mat-icon-button
  [attr.data-testid]="'button-user-profile'"
  class="avatar__centered avatar__initials avatar__circle"
  [matMenuTriggerFor]="profileMenu">
  @if (!showInitials) {
    <img
      alt="user"
      data-testid="image"
      class="avatar__circle"
      [matMenuTriggerFor]="profileMenu"
      [src]="photoUrl"/>
  }

  @if (showInitials) {
    <span data-testid=initials class="avatar__text">{{ initials }}</span>
  }
</button>

<mat-menu #profileMenu="matMenu" class="avatar-menu-{{ parent }}">
  <ng-content select="[mat-menu-item]"></ng-content>
</mat-menu>
