<div class="filters">
  @if (selectedCardType !== 'assets') {
    <div class="filter">
      <app-button-toggle-group header="Asset Group" class="filter__buttons"
        data-testid="assetHierarchy-buttonGroup-assetGroup"
        [selectedId]="(selectedAssetGroup$ | async)?.id" [items]="assetGroups$ | async"
        (selectItem)="onSelectAssetGroup($event)">
      </app-button-toggle-group>

      <mat-divider class="filter__line" vertical></mat-divider>
    </div>
  }

  <div class="filter">
    <app-button-toggle-group header="Asset" class="filter__buttons"
      data-testid="assetHierarchy-buttonGroup-asset"
      [selectedId]="(selectedAsset$ | async)?.id" [items]="assets$ | async"
      (selectItem)="onSelectAsset($event)">
    </app-button-toggle-group>

    <mat-divider class="filter__line" vertical></mat-divider>
  </div>

  <div class="filter">
    <app-button-toggle-group header="Generator" class="filter__buttons"
      data-testid="assetHierarchy-buttonGroup-generator"
      [selectedId]="(selectedGenerator$ | async)?.id"
      [items]="generators$ | async" (selectItem)="onSelectGenerator($event)">
    </app-button-toggle-group>
  </div>
</div>
