@defer {
  <app-container-actions/>

  @if ((auctionView$ | async) === 'summary') {
    <app-auction-summary-container/>
  }

  @if ((auctionView$ | async) === 'view-order' || (auctionView$ | async) === 'create-order') {
    @defer {
      <app-auction-order-container/>
    }
  }

  @if ((auctionView$ | async) === 'result') {
    @defer {
      <app-auction-result-container/>
    }
  }
}
