export const TelemetryEvents = {
  AuctionsSummaryRefresh: 'UiAuctionsSummaryRefresh',
  CardSelectionChanged: 'UiCardSelectionChanged',
  ExpandCollapseCardClicked: 'UiExpandCollapseCardClicked',
  InboxClicked: 'UiInboxClicked',
  InboxNotificationClicked: 'UiInboxNotificationClicked',
  PortfolioHierarchyChanged: 'UiPortfolioHierarchyChanged',
  ResolutionTypeFilterChanged: 'UiResolutionTypeFilterChanged',
  PeriodTypeFilterChanged: 'UiPeriodTypeFilterChanged',
  ReserveAssetGroupSelect: 'UiReserveAssetGroupSelect',
  ReserveAssetSelect: 'UiReserveAssetSelect',
  ReserveEditSetPointsClicked: 'UiReserveEditSetPointsClicked',
  ReserveEditSetPointsTierAdd: 'UiReserveEditSetPointsTierAdd',
  ReserveEditSetPointsTierDelete: 'UiReserveEditSetPointsTierDelete',
  ReserveEditSetPointsUpdateAssets: 'UiReserveEditSetPointsUpdateAssets',
  ReserveGeneratorSelect: 'UiReserveGeneratorSelect',
  ReserveScreenPriceOverrides: 'UiReserveScreenPriceOverrides',
  SignalRConnectionClosed: 'UiSignalRConnectionClosed',
  SignalRConnectionEstablished: 'UiSignalRConnectionEstablished',
  SignalRConnectionReconnected: 'UiSignalRConnectionReconnected',
  SignalRConnectionReconnecting: 'UiSignalRConnectionReconnecting',
  SubmittedToTSO: 'UiSubmittedToTSO',
  QuickTrade: 'UiQuickTrade',
  QuickTradeClosed: 'UiQuickTradeClosed',
  QuickTradeOpened: 'UiQuickTradeOpened',
  QtContractSelectionChanged: 'UiQtContractSelectionChanged',
  QtOrderTypeChanged: 'UiQtOrderTypeChanged',
  QtQuantityChanged: 'UiQtQuantityChanged',
  QtPriceChanged: 'UiQtPriceChanged',
  QtLabelChanged: 'UiQtLabelChanged',
  QtExpiryChanged: 'UiQtExpiryChanged',
} as const;

export type TelemetryEventsConstKeys = typeof TelemetryEvents[keyof typeof TelemetryEvents];

export enum TelemetryQtLocation {
  PositionScreenNOP = 'PositionScreenNOP',
  AssetScreenHeadFootRoom = 'AssetScreenHeadFootRoom',
}
