import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarComponent } from '@shared/snackbar/components/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {
  }

  public showSnackbar(snackBarText:string, isSuccessful:boolean):void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: { snackBarText, isSuccessful },
      duration: 5000,
    });
  }
}
