<div class="cards">
  @for (card of visibleCards; track card.type) {
    <app-card-toggle-item
      [title]="card.title"
      [name]="card.name"
      [selected]="card.isSelected"
      [cardContent]="card.contents"
      [attr.data-testid]="card.name"
      (click)="onSelectCard(card.type)"
      (keydown.enter)="onSelectCard(card.type)"
      [attr.tabindex]= "0"
    ></app-card-toggle-item>
  } @empty {
    <!-- display empty cards with no inputs while the cards are loaded -->
    <app-card-toggle-item [selected]="false"></app-card-toggle-item>
    <app-card-toggle-item [selected]="false"></app-card-toggle-item>
    <app-card-toggle-item [selected]="false"></app-card-toggle-item>
    <app-card-toggle-item [selected]="false"></app-card-toggle-item>
  }
</div>
