import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SubmitOrdersToTsoCommand } from '@bradyplc/brady.powerdesk.api.internal.mfrr.contracts';

import { environment } from '@env/environment';
import {
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  retry,
  switchMap,
} from 'rxjs/operators';

import { HandleErrorService } from '@core/handle-error.service';

import { ErrorDialogService } from '@shared/error-dialog/services/error-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class SubmitOrdersToTSOService {
  constructor(
    private http: HttpClient,
    private errorDialogService: ErrorDialogService,
    private handleErrorService: HandleErrorService,
  ) {}

  public submit(requestData: SubmitOrdersToTsoCommand): Observable<void> {
    const url: string = environment.submitOrdersToTSOUrl;

    return this.http.post<void>(url, requestData).pipe(
      retry({
        delay: (error: HttpErrorResponse) => this.errorDialogService.showErrorDialog({
          title: 'Error submitting orders to TSO',
          content: 'Something went wrong when we tried to submit orders to TSO.',
        }).pipe(
          switchMap((errorDialogResult) => {
            if (errorDialogResult?.shouldTryAgain) {
              return of(true);
            }

            const errorToThrow = new Error(error.message);
            throw errorToThrow;
          }),
        ),
      }),
      catchError((err:HttpErrorResponse) => this.handleErrorService.handleError(err)),
    );
  }
}
