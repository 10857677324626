import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Participant } from '@bradyplc/brady.powerdesk.api.internal.portfoliohierarchy.contracts';

import { environment } from '@env/environment';
import {
  BehaviorSubject,
  catchError,
  Observable,
  tap,
} from 'rxjs';

import { HandleErrorService } from '@core/handle-error.service';

@Injectable({
  providedIn: 'root',
})
export class ParticipantService {
  private selectedParticipantId$$ = new BehaviorSubject<string>('');
  public selectedParticipantId$ = this.selectedParticipantId$$.asObservable();

  constructor(
    private httpClient: HttpClient,
    private handleErrorService: HandleErrorService,
  ) {}

  public setParticipantId(id: string): void {
    this.selectedParticipantId$$.next(id);
  }

  public getParticipants(): Observable<Participant[]> {
    return this.httpClient.get<Participant[]>(
      `${environment.portfolioAdminApiUrl}/participants`,
    ).pipe(
      tap((response) => {
        if (!response) {
          this.selectedParticipantId$$.next('');
          return;
        }
        response.sort((a, b) => a.name.localeCompare(b.name));
        this.selectedParticipantId$$.next(response[0].id);
      }),
      catchError((err: HttpErrorResponse) => this.handleErrorService.handleError(err, true)),
    );
  }
}
