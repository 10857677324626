import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

import { CardToggleGroupType } from '../../../card-toggle-group/models/card-toggle-group.type';

@Component({
  selector: 'app-asset-hierarchy-container',
  templateUrl: './asset-hierarchy-container.component.html',
  styleUrls: ['./asset-hierarchy-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetHierarchyContainerComponent {
  @Input({ required: true }) selectedCardType: CardToggleGroupType | undefined;
}
