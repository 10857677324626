import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';

import {
  combineLatest,
  Observable,
} from 'rxjs';
import {
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

import { UtilsDateTimeService } from '@core/services/utils/utils-date-time.service';
import { UIStateStore } from '@core/state-store/ui-state-store.service';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClockComponent implements OnInit {
  public clock?: Observable<string>;

  constructor(
    private utilsDateTimeService: UtilsDateTimeService,
    public uiStateStore: UIStateStore,
  ) {
  }

  public ngOnInit(): void {
    this.clock = combineLatest([
      this.uiStateStore.timeZoneState$,
      this.uiStateStore.dateTimeLocaleState$,
    ]).pipe(
      switchMap(([timeZone, locale]) => this.uiStateStore.globalTimer$.pipe(
        startWith(0),
        map(() => this.utilsDateTimeService.getFormattedDateTime('HH:mm:ss ZZZZ', timeZone, locale)),
      )),
    );
  }
}
