<div class="container">
  <mat-icon mat-dialog-close class="icon-close">close</mat-icon>

  <mat-icon class="icon-warning">warning</mat-icon>

  <h2 class="container__header">Deactivate Orders</h2>

  <div class="container__content" data-testid="deactivate-orders-confirmation-popup-content">Would you like to deactivate all open orders across
    all trading exchanges for your organisation?
  </div>
</div>

<div class="actions">
  <button mat-button mat-dialog-close class="actions__cancel">NO, DON'T DEACTIVATE ORDERS</button>
  <button mat-button cdkFocusInitial class="actions__deactivate" (click)="onClickDeactivateOrders()">YES, DEACTIVATE
    ORDERS
  </button>
</div>
