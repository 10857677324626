import { ColDef } from '@ag-grid-community/core';

import { GridRowData } from '../../core/models/grid-row-data.model';

export const fillerColumnFieldName = 'fillerColumn';

export const COLUMN_DEFINITIONS = {
  width: 70,
  largeScreenWidth: 100,
};

export const emptyFillerColumn: ColDef<GridRowData> = {
  headerName: '',
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  suppressNavigable: true,
  field: fillerColumnFieldName,
  type: 'numericColumn',
  flex: 1,
};

// We have to slightly tweak the xAxisLeft value for 1h grid to make it align 100% with the grid period columns.
// Unfortunately, this is a 'magic' number that we have to adjust manually depending on the column width.
export const X_AXIS_DEFINITIONS = {
  left: 28,
  largeScreenLeft: 42,
};

export const getColumnWidth = (granularityMinutes: number): number => {
  let { width } = COLUMN_DEFINITIONS;

  // Joint decisiong with Product to have one breakpoint and increase the column width to 100px after
  // that breakpoint. Data in the grid looks too scattered when increasing the column width above 100.
  if (granularityMinutes === 60 && window.innerWidth > 1920) {
    width = COLUMN_DEFINITIONS.largeScreenWidth;
  }

  return width;
};

export const getXAxisLeft = (granularityMinutes: number): number => (
  getColumnWidth(granularityMinutes) === COLUMN_DEFINITIONS.width ? X_AXIS_DEFINITIONS.left : X_AXIS_DEFINITIONS.largeScreenLeft
);
