import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';

import { environment } from '@env/environment';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-footer-container',
  templateUrl: './footer-container.component.html',
  styleUrls: ['./footer-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterContainerComponent {
  public copyright = `© ${DateTime.now().year} Brady Software`;
  public bradyVersion = environment.bradyVersion;
}
