import { Injectable } from '@angular/core';

import { TimeSeriesResolution } from '@bradyplc/brady.powerdesk.api.internal.mfrr.contracts';

import { GranularityType } from '../../state-store/models/granularity.model';

@Injectable({
  providedIn: 'root',
})
export class UtilsGranularityService {
  public minuteTimeseriesResolitionMap: Record<number, TimeSeriesResolution> = {
    60: TimeSeriesResolution.PT60M,
    30: TimeSeriesResolution.PT30M,
    15: TimeSeriesResolution.PT15M,
  };

  public timeseriesResolutionGranularityMap: Record<TimeSeriesResolution | string, NonNullable<GranularityType>> = {
    [TimeSeriesResolution.PT60M]: '1h',
    [TimeSeriesResolution.PT30M]: '30m',
    [TimeSeriesResolution.PT15M]: '15m',
  };

  public timeseriesResolutionMinuteMap: Record<TimeSeriesResolution | string, number> = {
    [TimeSeriesResolution.PT60M]: 60,
    [TimeSeriesResolution.PT30M]: 30,
    [TimeSeriesResolution.PT15M]: 15,
  };

  public convertGranularityTypeToText(granularity: GranularityType): string {
    let mappedGranularity: string | undefined;
    switch (granularity) {
      case '1h':
        mappedGranularity = '60';
        break;
      case '15m':
        mappedGranularity = '15';
        break;
      case '30m':
        mappedGranularity = '30';
        break;
      default:
        throw new Error('Granularity does not exist');
    }

    return mappedGranularity;
  }

  public granularityTypeToTimeseriesResolution(granularity: GranularityType): TimeSeriesResolution {
    switch (granularity) {
      case '1h':
        return TimeSeriesResolution.PT60M;
      case '15m':
        return TimeSeriesResolution.PT15M;
      case '30m':
        return TimeSeriesResolution.PT30M;
      default:
        throw new Error('Granularity is "null"');
    }
  }
}
