import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ErrorDialogDataModel } from '../../models/error-dialog-data.model';
import { ErrorDialogResultModel } from '../../models/error-dialog-result.model';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent {
  public isTypeValidation = false;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent, ErrorDialogResultModel>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogDataModel,
  ) {
    this.isTypeValidation = data?.type === 'validation';
  }

  public onClickTryAgain(): void {
    this.dialogRef.close({
      shouldTryAgain: true,
    });
  }
}
