import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

import { CardToggleItemContentTuple } from '../../models/card-toggle-item-content.type';

@Component({
  selector: 'app-card-toggle-item',
  templateUrl: './card-toggle-item.component.html',
  styleUrls: ['./card-toggle-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardToggleItemComponent {
  @Input() selected: boolean = false;
  @Input() title?: string;
  @Input() name?: string;
  @Input() cardContent?: CardToggleItemContentTuple;
}
