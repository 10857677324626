import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { ButtonToggleModel } from '../../models/button-toggle.model';

@Component({
  selector: 'app-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonToggleGroupComponent {
  @Input() selectedId: string | undefined;
  @Input() header?: string;
  @Input() items: ButtonToggleModel[] | undefined | null;

  @Output() readonly selectItem = new EventEmitter<string>();

  public get selectedItem(): ButtonToggleModel | undefined {
    return this?.items?.find((x) => x.id === this.selectedId);
  }

  public onSelect(item: ButtonToggleModel | undefined): void {
    if (!item) {
      return;
    }

    this.selectItem.emit(item.id);
  }
}
