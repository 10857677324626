import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InboxPanelService {
  private showPanel$$ = new BehaviorSubject<boolean>(false);
  public showPanel$: Observable<boolean> = this.showPanel$$.asObservable();

  public get showPanel(): boolean {
    return this.showPanel$$.value;
  }

  public togglePanel(shouldShow: boolean): void {
    this.showPanel$$.next(shouldShow);
  }
}
