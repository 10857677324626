import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  RequestedFeeds,
  SnapshotResult,
} from '@bradyplc/brady.powerdesk.api.internal.frontendfeeds.contracts';

import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  Observable,
  from,
} from 'rxjs';
import {
  concatMap,
  filter,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ProgressIndicatorService } from '../services/progress-indicator.service';
import { UtilsDateTimeService } from '../services/utils/utils-date-time.service';
import { UIStateStore } from '../state-store/ui-state-store.service';

@Injectable({
  providedIn: 'root',
})
export class FeedsApiService {
  private signalrConnectionId = new BehaviorSubject<string | null>(null);

  constructor(
    private http: HttpClient,
    private utilsDateTimeService: UtilsDateTimeService,
    private uiStateStore: UIStateStore,
    private progressIndicator: ProgressIndicatorService,
  ) { }

  public setSignalrConnectionId(id: string | null): void {
    this.signalrConnectionId.next(id);
  }

  public loadSnapshot$(
    feedId: string,
    maxSize: number,
    period: { start: DateTime; end: DateTime } | null = null,
    timeSettings?: { tradingTimeOffset: number; timeZone: string },
  ): Observable<SnapshotResult> {
    const httpParams: Record<string, string | number> = { feedId, 'max-notifications': maxSize };
    const isTimeseriesFeed = feedId.includes('timeseries');
    const tradingTimeOffset = timeSettings?.tradingTimeOffset ?? this.uiStateStore.countryConfigState.tradingTimeOffset;

    if (period) {
      httpParams['minDate'] = period.start.toUTC().toISO();
      httpParams['maxDate'] = period.end.toUTC().toISO();
    } else if (isTimeseriesFeed || feedId.includes('contracts') || feedId.includes('market-depth')) {
      const { startDateTime, endDateTime } = this.utilsDateTimeService.getStartAndEndDateTime(
        this.uiStateStore.periodState,
        timeSettings?.timeZone || this.uiStateStore.timeZoneState,
        true,
      );
      httpParams['minDate'] = startDateTime.plus({ minutes: tradingTimeOffset }).toISO();
      httpParams['maxDate'] = endDateTime.plus({ minutes: tradingTimeOffset }).toISO();
    }

    if (isTimeseriesFeed) {
      this.progressIndicator.displayProgressIndicator(false);
    }

    return this.http.get<SnapshotResult>(
      environment.snapshotReaderUrl,
      { params: new HttpParams({ fromObject: httpParams }) },
    ).pipe(tap(() => {
      if (isTimeseriesFeed) {
        this.progressIndicator.hideProgressIndicator();
      }
    }));
  }

  public addUserToSubscriptions$(feeds: string[]): Observable<RequestedFeeds> {
    return this.signalrConnectionId.asObservable().pipe(
      filter(((id) => !!id)),
      take(1),
      switchMap((connectionId) => (
        this.http.post<RequestedFeeds>(`${environment.signalRAddToGroupUrl}`, feeds, { headers: this.getHeaders(connectionId as string) })
      )),
    );
  }

  public deleteUserSubscriptions$(feeds: string[]): Observable<unknown> {
    // unsubscribe from all feeds (10 at a time to avoid hitting the max query string length limit)
    const chunkSize = 10;
    const feedChunks: string[][] = [];

    // split feeds into chunks of 10
    for (let i = 0; i < feeds.length; i += chunkSize) {
      feedChunks.push(feeds.slice(i, i + chunkSize));
    }

    return this.signalrConnectionId.asObservable().pipe(
      filter((id) => !!id),
      take(1),
      switchMap((connectionId) => from(feedChunks).pipe(
        // concatMap ensures each chunk, 10 at a time, is processed
        // eg: 24 feeds will create 3 requests, 10, 10, 4
        concatMap((feedChunk) => {
          const idParams: string = feedChunk.map((feedId) => `id=${feedId}`).join('&');
          return this.http.delete(`${environment.signalRRemoveGroupsUrl}/?${idParams}`, { headers: this.getHeaders(connectionId as string) });
        }),
      )),
    );
  }

  private getHeaders(connectionId: string): Record<string, string | string[]> {
    const headers: Record<string, string | string[]> = {};
    headers['ConnectionId'] = connectionId;
    headers['IsFromPDApp'] = '1';
    return headers;
  }
}
