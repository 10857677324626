import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';

import { SelectedPortfolioHierarchyModel } from '../../models/selected-portfolio-hierarchy.model';
import { PortfolioHierarchyFacadeService } from '../../services/portfolio-hierarchy-facade.service';

@Component({
  selector: 'app-portfolio-hierarchy-container',
  templateUrl: './portfolio-hierarchy-container.component.html',
  styleUrls: ['./portfolio-hierarchy-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioHierarchyContainerComponent implements OnInit {
  public portfolioHierarchy$ = this.portfolioHierarchyFacadeService.portfolioHierarchy$;

  public selectedPortfolioHierarchy$ = this.portfolioHierarchyFacadeService.selectedPortfolioHierarchy$;

  constructor(
    private portfolioHierarchyFacadeService: PortfolioHierarchyFacadeService,
  ) {}

  public ngOnInit(): void {
    this.portfolioHierarchyFacadeService.loadPortfolioHierarchy();
  }

  public onChangePortfolioHierarchy(
    event: SelectedPortfolioHierarchyModel,
  ): void {
    this.portfolioHierarchyFacadeService.setSelectedPortfolioHierarchy(event);
  }
}
