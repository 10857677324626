import {
  ChangeDetectionStrategy,
  Component,
  Inject,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-component',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class SnackbarComponent {
  public snackBarData:string;
  public isSuccessful:boolean;

  constructor(@Inject(MAT_SNACK_BAR_DATA) data: { snackBarText:string; isSuccessful:boolean }) {
    this.snackBarData = data.snackBarText;
    this.isSuccessful = data.isSuccessful;
  }
}
