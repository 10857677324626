import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

import { ButtonToggleGroupModule } from '@shared/button-toggle-group/button-toggle-group.module';

import { AssetHierarchyComponent } from './components/asset-hierarchy/asset-hierarchy.component';
import { AssetHierarchyContainerComponent } from './containers/asset-hierarchy-container/asset-hierarchy-container.component';

@NgModule({
  declarations: [AssetHierarchyContainerComponent, AssetHierarchyComponent],
  exports: [AssetHierarchyContainerComponent],
  imports: [CommonModule, ButtonToggleGroupModule, MatDividerModule],
})
export class AssetHierarchyModule {}
