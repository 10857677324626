import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

import { PortfolioHierarchyComponent } from './components/portfolio-hierarchy/portfolio-hierarchy.component';
import { PortfolioHierarchyContainerComponent } from './containers/portfolio-hierarchy-container/portfolio-hierarchy-container.component';

@NgModule({
  declarations: [
    PortfolioHierarchyContainerComponent,
    PortfolioHierarchyComponent,
  ],
  exports: [
    PortfolioHierarchyContainerComponent,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
  ],
})
export class PortfolioHierarchyModule {
}
