import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  IHttpConnectionOptions,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { TelemetryEvents } from '../models/telemetry.enum';
import { TelemetryService } from '../services/telemetry.service';

import { SignalRConnectionModel } from './models/signal-r-connection.model';
import { SignalrStatusTypeEnum } from './models/signalr-status-type.enum';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  public status: SignalrStatusTypeEnum = SignalrStatusTypeEnum.Disconnected;
  public hubConnection: HubConnection | null = null;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private telemetryService: TelemetryService,
  ) {
  }

  public async initializeSignalRConnection(): Promise<void> {
    const clientConnection: SignalRConnectionModel = await firstValueFrom(
      this.http.post<SignalRConnectionModel>(`${environment.signalRUrl}`, {}),
    );

    const options: IHttpConnectionOptions = { accessTokenFactory: () => clientConnection.accessToken };

    const hubBuilder = new HubConnectionBuilder()
      .withUrl(clientConnection.url, options)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information);

    this.hubConnection = hubBuilder.build();
  }

  public async connect(): Promise<string | null> {
    if (!this.hubConnection) {
      this.status = SignalrStatusTypeEnum.Disconnected;
      throw new Error('HubConnection is not initialized!');
    }

    try {
      this.status = SignalrStatusTypeEnum.Connecting;
      await this.hubConnection.start();
      this.status = SignalrStatusTypeEnum.Connected;

      this.logger.debug('SignalR connection established.', this.hubConnection.connectionId);
      this.telemetryService.logEvent(TelemetryEvents.SignalRConnectionEstablished);
      return this.hubConnection.connectionId;
    } catch (error) {
      this.status = SignalrStatusTypeEnum.Disconnected;
      this.logger.error(error);
      this.telemetryService.logException(error as Error);
      return null;
    }
  }

  public async disconnect(): Promise<void> {
    if (!this.hubConnection) {
      this.status = SignalrStatusTypeEnum.Disconnected;
      throw new Error('HubConnection is not initialized!');
    }

    try {
      await this.hubConnection.stop();
      this.status = SignalrStatusTypeEnum.Disconnected;
    } catch (error) {
      this.logger.error(error);
      this.telemetryService.logException(error as Error);
    }
  }
}
