import {
  ChangeDetectionStrategy,
  Component,
  Inject,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import {
  DateTime,
} from 'luxon';

import { UIStateStore } from '@core/state-store/ui-state-store.service';

import { NotificationModel } from '../../models/notification.model';
import { NotificationsFacadeService } from '../../services/notifications-facade.service';

@Component({
  selector: 'app-alert-container',
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertContainerComponent {
  public notification: NotificationModel;
  public notificationTimeStamp: string = '';

  constructor(
    public notificationsFacade: NotificationsFacadeService,
    public dialogRef: MatDialogRef<AlertContainerComponent>,
    public uiStateStore: UIStateStore,
    @Inject(MAT_DIALOG_DATA) public data: NotificationModel,
  ) {
    this.notification = data;
    this.notificationTimeStamp = this.notificationDate(this.notification.date);
  }

  public onClickAcknowledge(): void {
    if (this.notification.target !== 'critical') {
      this.notificationsFacade.toggleAcknowledgement(this.notification);
    }
    this.dialogRef.close();
  }

  public notificationDate(date: DateTime): string {
    const isToday = date.hasSame(DateTime.local(), 'day') ? 'Today, ' : '';
    const notificationDate = date.setZone(this.uiStateStore.timeZoneState)
      .setLocale(this.uiStateStore.dateTimeLocaleState);

    return `${isToday}${notificationDate.toFormat('d MMMM yyyy')} at ${notificationDate.toFormat('HH:mm:ss ZZZZ')}`;
  }
}
