import {
  Acknowledgment,
  IntradayContract,
  MarketDepth,
  Notification,
  TimeSeriesSegment,
  TimeSeriesValue,
} from '@bradyplc/brady.powerdesk.api.internal.frontendfeeds.contracts';
import { SetPointsResult } from '@bradyplc/brady.powerdesk.api.internal.setpoints.contracts';

import type { Big } from 'big.js';
import { List } from 'immutable';
import { DateTime } from 'luxon';

import { UpdateBuffer } from './update-buffer';

export type NotificationType = 'info' | 'inbox' | 'alert' | 'critical';

export interface NotificationResponse extends Omit<Notification, 'type'> {
  type: NotificationType;
}

export interface TimeseriesValueModel extends Omit<TimeSeriesValue, 'start' | 'end' | 'numericValue'> {
  utcDate: string;
  start: DateTime;
  end: DateTime;
  numericValue: Big;
}

export interface TimeseriesSegmentModel extends Omit<TimeSeriesSegment, 'id' | 'utcDate' | 'values'> {
  rowName: string;
  values: TimeseriesValueModel[];
}

interface FeedData {
  notifications: List<NotificationResponse>;
  acknowledgments: List<Acknowledgment>;
  timeseriesSegments: List<TimeseriesSegmentModel>;
  contracts: List<IntradayContract>;
  marketDepth?: MarketDepth;
  setPoints: SetPointsResult;
}

export class Feed {
  id: string;
  sequenceNumber = -1;
  heartbeatSequenceNumber = -1;
  heartbeatTimestamp: number | undefined = undefined;
  heartbeatAheadTimestamp: number | undefined = undefined;
  buffer: UpdateBuffer = new UpdateBuffer();

  data: FeedData = {
    notifications: List([]),
    acknowledgments: List([]),
    timeseriesSegments: List([]),
    contracts: List([]),
    setPoints: { setPointsFlexTiers: [], setPointsOpsStartTiers: [] },
  };

  status = `Disconnected`;

  constructor(id: string) {
    this.id = id;
  }
}
