import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/tooltip';

import { ErrorBannerComponent } from '@shared/error-banner/error-banner.component';

import { AssetHierarchyModule } from '../asset-hierarchy/asset-hierarchy.module';
import { AuctionContainerComponent } from '../auction/components/auction-container/auction-container.component';
import { CardToggleGroupModule } from '../card-toggle-group/card-toggle-group.module';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';

import { HomeContainerComponent } from './containers/home-container/home-container.component';

@NgModule({
  declarations: [
    HomeContainerComponent,
  ],
  imports: [
    AuctionContainerComponent,
    CommonModule,
    PortalModule,
    CardToggleGroupModule,
    HeaderModule,
    MatSidenavModule,
    MatIconModule,
    MatDialogModule,
    MatNativeDateModule,
    MatProgressBarModule,
    FooterModule,
    AssetHierarchyModule,
    ErrorBannerComponent,
  ],
  exports: [
    HomeContainerComponent,
  ],
  providers: [MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER, MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class HomeModule {
}
