import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { FooterContainerComponent } from './containers/footer-container/footer-container.component';

@NgModule({
  declarations: [
    FooterContainerComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [
    FooterContainerComponent,
  ],
})
export class FooterModule {
}
