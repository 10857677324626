import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { HeaderModule } from '../header/header.module';

import { AccessDeniedComponent } from './containers/access-denied/access-denied.component';
import { ErrorContainerComponent } from './containers/error-container/error-container.component';

@NgModule({
  declarations: [
    ErrorContainerComponent,
    AccessDeniedComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    MatIconModule,
  ],
  exports: [
    ErrorContainerComponent,
    AccessDeniedComponent,
  ],
})
export class ErrorModule {
}
