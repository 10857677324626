export enum TimeSeriesSegmentTypeEnum {
  StartTime = 'Start Time',
  DAPrice = 'DA Price',
  MaxGen = 'Max Gen',
  ProdPlan = 'Prod Plan',
  ExtProdPlan = 'Ext Prod Plan',
  MinGen = 'Min Gen',
  FCRN = 'FCRN',
  FCRD = 'FCRD',
  AFRRUp = 'aFRR Up',
  AFRRDown = 'aFRR Down',
}
