import {
  Injectable,
  NgZone,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
  IntradayContract,
  MarketDepth,
} from '@bradyplc/brady.powerdesk.api.internal.frontendfeeds.contracts';
import { SetPointsResult } from '@bradyplc/brady.powerdesk.api.internal.setpoints.contracts';

import { List } from 'immutable';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  delay,
} from 'rxjs/operators';

import {
  AlertContainerComponent,
} from '../../modules/notifications/containers/alert-container/alert-container.component';
import { NotificationModel } from '../../modules/notifications/models/notification.model';
import { SnackbarService } from '../services/snackbar-service';

import { FeedsService } from './feeds.service';
import {
  Feed,
  TimeseriesSegmentModel,
} from './models/feed';

@Injectable({
  providedIn: 'root',
})
export class FeedsEngineService {
  // TODO: Not yet decided when to show spinner
  private isLoadingNotifications$$ = new BehaviorSubject<boolean>(false);
  public isLoadingNotifications$ = this.isLoadingNotifications$$.asObservable();

  private dialogPanelClass = 'alert-dialog-panel';

  constructor(
    private zone: NgZone,
    private dialog: MatDialog,
    private feedsService: FeedsService,
    private snackbarService: SnackbarService,
  ) {
    this.feedsService.latestNotificationUpdate$.pipe(
      filter((x) => x.type === 'alert'),
      switchMap((notification) => {
        if (this.dialog.openDialogs.length > 0) {
          this.dialog.openDialogs.forEach((dialogRef) => dialogRef.close());
        }

        return of('').pipe(
          delay(400),
          switchMap(() => this.dialog.open<AlertContainerComponent>(AlertContainerComponent, {
            data: {
              id: notification.id,
              title: notification.title,
              content: notification.content,
              date: DateTime.fromMillis(Number.parseInt(notification.timestamp.toString())),
              target: notification.type,
              isAcknowledged: notification.isAcknowledged,
            } as NotificationModel,
            panelClass: this.dialogPanelClass,
            disableClose: true,
          }).afterClosed()),
        );
      }),
    ).subscribe();

    this.feedsService.latestNotificationUpdate$.pipe(
      filter((x) => x.type === 'info'),
    ).subscribe((notification) => {
      this.zone.run(() => {
        this.snackbarService.showSnackbar(notification.content, true);
      });
    });
  }

  public getAllNotifications$(): Observable<NotificationModel[]> {
    return this.feedsService.mandatoryFeedIds$.pipe(
      switchMap((feedIds) => {
        const feeds$ = feedIds.map<Observable<Feed>>((feedId) => this.feedsService.getFeedById$(feedId));
        return combineLatest(feeds$);
      }),
    ).pipe(
      debounceTime(100),
      map((feeds) => {
        const feedsList = List(feeds);
        const allAcknowledgments = feedsList.flatMap((f) => f.data.acknowledgments);

        return feedsList
          .flatMap((feed) => feed.data.notifications.map<NotificationModel>((n) => {
            const acknowledgment = allAcknowledgments.find((a) => a.notificationId === n.id);
            return {
              id: n.id,
              title: n.title,
              content: n.content,
              date: DateTime.fromMillis(Number.parseInt(n.timestamp.toString())),
              target: n.type,
              type: feed.id.endsWith('|user-group') ? 'group' : 'personal',
              acknowledgment,
              children: [],
              isAcknowledged: acknowledgment ? true : n.isAcknowledged,
            };
          }))
          .sort((a, b) => +b.date - +a.date)
          .toArray();
      }),
    );
  }

  public getTimeseries(feedId: string): Observable<List<TimeseriesSegmentModel>> {
    return this.feedsService.getFeedById$(feedId).pipe(
      debounceTime(100),
      map((feed) => feed.data.timeseriesSegments),
      distinctUntilChanged(),
    );
  }

  public getContracts(feedId: string): Observable<List<IntradayContract>> {
    return this.feedsService.getFeedById$(feedId).pipe(
      map((feed) => feed.data.contracts),
      distinctUntilChanged(),
    );
  }

  public getMarketDepth(feedId: string): Observable<MarketDepth | null> {
    return this.feedsService.getFeedById$(feedId).pipe(
      map((feed) => feed.data.marketDepth || null),
      distinctUntilChanged(),
    );
  }

  public getSetPoints(feedId: string): Observable<SetPointsResult> {
    return this.feedsService.getFeedById$(feedId).pipe(
      map((feed) => feed.data.setPoints),
      distinctUntilChanged(),
    );
  }
}
