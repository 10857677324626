import { Injectable } from '@angular/core';

import { OrderManagementType } from '@bradyplc/brady.powerdesk.api.internal.intraday.contracts';

import { Observable } from 'rxjs';

import { PortfolioHierarchyFacadeService } from '../../portfolio-hierarchy/services/portfolio-hierarchy-facade.service';

import { ManageOrdersApiService } from './manage-orders-api.service';

@Injectable({
  providedIn: 'root',
})
export class ManageOrdersFacadeService {
  constructor(
    private portfolioHierarchyFacade: PortfolioHierarchyFacadeService,
    private manageOrdersApiService: ManageOrdersApiService,
  ) {
  }

  public deactivateOrders$(): Observable<void> {
    const participantId = this.portfolioHierarchyFacade.selectedPortfolioHierarchy?.participantId as string;

    return this.manageOrdersApiService.manageOrders$(
      OrderManagementType.DEAC_COMPANY_ORDERS,
      participantId,
    );
  }
}
