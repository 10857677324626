import { Injectable } from '@angular/core';

import { HeartbeatResult } from '@bradyplc/brady.powerdesk.api.internal.frontendfeeds.contracts';

import { DateTime } from 'luxon';

import { FeedsService } from '../feeds.service';
import { SignalRService } from '../signal-r.service';

@Injectable({
  providedIn: 'root',
})
export class HeartbeatHandlerService {
  constructor(
    private feedsService: FeedsService,
    private signalRService: SignalRService,
  ) {
  }

  public initialize(): void {
    this.signalRService.hubConnection?.on(
      'onHeartbeat',
      (event: string) => this.onHeartbeat(event),
    );
  }

  private onHeartbeat(event: string) {
    const result = JSON.parse(event) as HeartbeatResult;
    const feed = this.feedsService.getFeedById(result.feedId);
    if (!feed) {
      throw new Error(`Feed "${result.feedId}" can't be found!`);
    }

    if (feed.heartbeatSequenceNumber < result.sequenceNumber) {
      feed.heartbeatSequenceNumber = result.sequenceNumber;
    }

    feed.heartbeatTimestamp = DateTime.now().toMillis();

    // Check if the heartbeat is ahead and record the time
    if (feed.sequenceNumber < feed.heartbeatSequenceNumber) {
      if (!feed.heartbeatAheadTimestamp) {
        feed.heartbeatAheadTimestamp = feed.heartbeatTimestamp;
      }
    } else {
      feed.heartbeatAheadTimestamp = undefined;
    }

    this.feedsService.updateFeed(feed);
  }
}
