import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardItemName',
})
export class CardItemNamePipe implements PipeTransform {
  transform(value: string | undefined): unknown {
    const labels = [
      { type: 'Available Generation', replaceWith: 'Available Gen' },
      { type: 'Reserve Market Commitment', replaceWith: 'Reserve Market' },
      { type: 'Upward Commitment', replaceWith: 'Upward' },
      { type: 'Downward Commitment', replaceWith: 'Downward' },
    ];

    return labels.find((label) => label.type === value)?.replaceWith ?? value;
  }
}
