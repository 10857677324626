import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  CountryResult,
  DeliveryAreaResult,
  PortfolioResult,
} from '@bradyplc/brady.powerdesk.api.internal.systemreferencedata.contracts';

import { SelectedPortfolioHierarchyModel } from '../../models/selected-portfolio-hierarchy.model';

@Component({
  selector: 'app-portfolio-hierarchy',
  templateUrl: './portfolio-hierarchy.component.html',
  styleUrls: ['./portfolio-hierarchy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioHierarchyComponent implements OnChanges {
  @Input() selectedPortfolioHierarchy?: SelectedPortfolioHierarchyModel | null;
  @Input() portfolioHierarchy?: CountryResult[] | null;

  @Output() readonly change = new EventEmitter<SelectedPortfolioHierarchyModel>();

  public selectedCountry?: CountryResult;
  public selectedDeliveryArea?: DeliveryAreaResult;
  public selectedPortfolio?: PortfolioResult;

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.['selectedPortfolioHierarchy']?.currentValue
      || changes?.['portfolioHierarchy']?.currentValue
    ) {
      this.selectedCountry = this.portfolioHierarchy?.find(
        (x) => x.id === this.selectedPortfolioHierarchy?.countryId,
      ) ?? this.portfolioHierarchy?.[0];

      this.selectedDeliveryArea = this.selectedCountry?.deliveryAreas.find(
        (x) => x.id === this.selectedPortfolioHierarchy?.deliveryAreaId,
      ) ?? this.selectedCountry?.deliveryAreas[0];

      this.selectedPortfolio = this.selectedDeliveryArea?.portfolios.find(
        (x) => x.id === this.selectedPortfolioHierarchy?.portfolioId,
      ) ?? this.selectedDeliveryArea?.portfolios[0];

      if ((this.selectedDeliveryArea?.portfolios.length ?? 0) <= 0) {
        this.selectedPortfolio = undefined;
      }
    }
  }

  public onChangeCountry(country?: CountryResult): void {
    this.change.emit({
      countryId: country?.id,
      deliveryAreaId: country?.deliveryAreas[0]?.id,
      portfolioId: country?.deliveryAreas[0]?.portfolios[0]?.id,
      nonPrefixedPortfolioId: country?.deliveryAreas[0]?.portfolios[0]?.portfolioId,
      participantId: country?.deliveryAreas[0]?.portfolios[0]?.participantId,
      nonPrefixedParticipantId: country?.deliveryAreas[0]?.portfolios[0]?.participant?.participantId,
      deliveryAreaEicCode: country?.deliveryAreas[0]?.eicCode,
    });
  }

  public onChangeDeliveryArea(deliveryArea?: DeliveryAreaResult): void {
    this.change.emit({
      countryId: this.selectedCountry?.id,
      deliveryAreaId: deliveryArea?.id,
      portfolioId: deliveryArea?.portfolios[0]?.id,
      nonPrefixedPortfolioId: deliveryArea?.portfolios[0]?.portfolioId,
      participantId: deliveryArea?.portfolios[0]?.participantId,
      nonPrefixedParticipantId: deliveryArea?.portfolios[0]?.participant?.participantId,
      deliveryAreaEicCode: deliveryArea?.eicCode,
    });
  }

  public onChangePortfolio(portfolio?: PortfolioResult): void {
    this.change.emit({
      countryId: this.selectedCountry?.id,
      deliveryAreaId: this.selectedDeliveryArea?.id,
      portfolioId: portfolio?.id,
      nonPrefixedPortfolioId: portfolio?.portfolioId,
      participantId: portfolio?.participantId,
      nonPrefixedParticipantId: portfolio?.participant?.participantId,
      deliveryAreaEicCode: this.selectedDeliveryArea?.eicCode,
    });
  }
}
