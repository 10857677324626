declare global {
  interface Window {
    env: {
      bradyVersion: string;
      appInsightsKey: string;
      agGridLicenseKey: string;
      auth0Namespace: string;
      signalRUrl: string;
      signalRRemoveAllGroupsUrl: string;
      signalRRemoveGroupsUrl: string;
      signalRAddToGroupUrl: string;
      snapshotReaderUrl: string;
      acknowledgmentsUrl: string;
      portfolioHierarchyUrl: string;
      assetHierarchyUrl: string;
      auth0Domain: string;
      auth0ClientId: string;
      auth0Audience: string;
      getSetPointsUrl: string;
      updateSetPointsUrl: string;
      saveMfrrPriceUrl: string;
      submitOrdersToTSOUrl: string;
      getTimeSeriesUrl: string;
      settingsApiUrl: string;
      submitIntradayOrderUrl: string;
      manageOrdersUrl: string;
      accessManagementApiUrl :string;
      portfolioAdminApiUrl: string;
      auctionsApiUrl: string;
      production: boolean;
    };
  }
}
window.env = window.env || {};

export const environment = {
  bradyVersion: window.env.bradyVersion,
  appInsightsKey: window.env.appInsightsKey,
  agGridLicenseKey: window.env.agGridLicenseKey,
  signalRUrl: window.env.signalRUrl,
  signalRRemoveAllGroupsUrl: window.env.signalRRemoveAllGroupsUrl,
  signalRRemoveGroupsUrl: window.env.signalRRemoveGroupsUrl,
  signalRAddToGroupUrl: window.env.signalRAddToGroupUrl,
  snapshotReaderUrl: window.env.snapshotReaderUrl,
  acknowledgmentsUrl: window.env.acknowledgmentsUrl,
  portfolioHierarchyUrl: window.env.portfolioHierarchyUrl,
  assetHierarchyUrl: window.env.assetHierarchyUrl,
  auth0Namespace: window.env.auth0Namespace,
  auth0Domain: window.env.auth0Domain,
  auth0ClientId: window.env.auth0ClientId,
  auth0Audience: window.env.auth0Audience,
  getSetPointsUrl: window.env.getSetPointsUrl,
  updateSetPointsUrl: window.env.updateSetPointsUrl,
  saveMfrrPriceUrl: window.env.saveMfrrPriceUrl,
  submitOrdersToTSOUrl: window.env.submitOrdersToTSOUrl,
  getTimeSeriesUrl: window.env.getTimeSeriesUrl,
  settingsApiUrl: window.env.settingsApiUrl,
  submitIntradayOrderUrl: window.env.submitIntradayOrderUrl,
  manageOrdersUrl: window.env.manageOrdersUrl,
  accessManagementApiUrl: window.env.accessManagementApiUrl,
  portfolioAdminApiUrl: window.env.portfolioAdminApiUrl,
  auctionsApiUrl: window.env.auctionsApiUrl,
  production: true,
};
