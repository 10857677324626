import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  GetSetPointsQuery,
  SetPointsResult,
  UpdateSetPointsCommand,
} from '@bradyplc/brady.powerdesk.api.internal.setpoints.contracts';

import { environment } from '@env/environment';
import { DateTime } from 'luxon';
import {
  Observable, of,
} from 'rxjs';
import {
  catchError,
  map,
  retry,
  switchMap,
} from 'rxjs/operators';

import { HandleErrorService } from '@core/handle-error.service';

import { ErrorDialogService } from '@shared/error-dialog/services/error-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class SetPointsService {
  constructor(
    private http: HttpClient,
    private errorDialogService: ErrorDialogService,
    private handleErrorService: HandleErrorService,
  ) {
  }

  public getSetPoints$(setPointsGetRequest: GetSetPointsQuery): Observable<SetPointsResult> {
    const url = environment.getSetPointsUrl;
    return this.http.post<SetPointsResult>(url, setPointsGetRequest).pipe(
      map((response) => ({
        ...response,
        setPointsOpsStartTiers: response.setPointsOpsStartTiers.map((tier) => ({
          ...tier,
          effectiveFrom: DateTime.fromISO(tier.effectiveFrom as unknown as string),
        })),
        setPointsFlexTiers: response.setPointsFlexTiers.map((tier) => ({
          ...tier,
          effectiveFrom: DateTime.fromISO(tier.effectiveFrom as unknown as string),
        })),
      })),
      catchError((err:HttpErrorResponse) => this.handleErrorService.handleError(err)),
    );
  }

  public updateSetPoints$(request: UpdateSetPointsCommand): Observable<void> {
    const url = environment.updateSetPointsUrl;

    const mapDatesToDateTime = {
      ...request,
      setPointsOpsStartTiers: request.setPointsOpsStartTiers.map((tier) => ({
        ...tier,
        effectiveFrom: tier.effectiveFrom.setZone('utc').toFormat('yyyy-MM-dd\'T\'HH:mm:ss\'Z\''),
      })),
      setPointsFlexTiers: request.setPointsFlexTiers.map((tier) => ({
        ...tier,
        effectiveFrom: tier.effectiveFrom.setZone('utc').toFormat('yyyy-MM-dd\'T\'HH:mm:ss\'Z\''),
      })),
    };

    return this.http.post<void>(url, mapDatesToDateTime).pipe(
      retry({
        delay: (error: HttpErrorResponse) => this.errorDialogService.showErrorDialog({
          title: 'Error saving your settings',
          content: this.generateMessage(error),
        }).pipe(
          switchMap((errorDialogResult) => {
            if (errorDialogResult?.shouldTryAgain) {
              return of(true);
            }

            const errorToThrow = new Error(error.message);
            throw errorToThrow;
          }),
        ),
      }),
      catchError((err:HttpErrorResponse) => this.handleErrorService.handleError(err)),
    );
  }

  private generateMessage(errorResponse: HttpErrorResponse): string {
    if (errorResponse.status === 409) {
      return 'Someone has updated the set points. Please close the set points panel and open it again!';
    }

    return 'Something went wrong when we tried to save your work.';
  }
}
