import { inject } from '@angular/core';
import { Router } from '@angular/router';

import {
  filter,
  map,
  Observable,
} from 'rxjs';

import { LoggedUserService } from '../services/logged-user.service';

export const permissionGuard = (): Observable<boolean> => {
  const loggedUserService = inject(LoggedUserService);
  const router = inject(Router);

  return loggedUserService.loggedUser$.pipe(
    filter((user) => !!user?.id),
    map(() => {
      if (loggedUserService.permissions.length === 0) {
        void router.navigate(['access-denied']);
        return false;
      }
      return true;
    }),
  );
};
