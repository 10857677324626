import { Injectable, signal } from '@angular/core';

import {
  BehaviorSubject,
  interval,
  Observable,
} from 'rxjs';

import { CardToggleGroupType } from '../../modules/card-toggle-group/models/card-toggle-group.type';
import { TelemetryEvents } from '../models/telemetry.enum';
import { LocalStorageService } from '../services/local-storage.service';
import { TelemetryService } from '../services/telemetry.service';

import { AuctionUILayout, AuctionUILayoutMapping, AuctionUIViewProperties } from './models/auctions.model';
import { CollapsedCardsState } from './models/collapsed-cards.model';
import { CountryConfig } from './models/country-config.model';
import { GranularityType } from './models/granularity.model';
import { PeriodType } from './models/period.model';

@Injectable({
  providedIn: 'root',
})
export class UIStateStore {
  public readonly globalTimer$ = interval(1000);
  public auctionsLayout$ = signal<AuctionUILayoutMapping>({
    newOrderLayout: 'grid-graph',
    viewOrderLayout: 'grid-graph',
    resultLayout: 'grid-graph',
  });

  private readonly activeScreen$$ = new BehaviorSubject<CardToggleGroupType>('auction');
  private readonly periodState$$ = new BehaviorSubject<PeriodType>('Today');
  private readonly currencyState$$ = new BehaviorSubject<string>('EUR');
  private readonly granularityState$$ = new BehaviorSubject<GranularityType>('1h');
  private readonly timeZoneState$$ = new BehaviorSubject<string>('Europe/Oslo');
  private readonly dateTimeLocaleState$$ = new BehaviorSubject<string>('en-GB');
  private readonly collapsedCardsState$$ = new BehaviorSubject<CollapsedCardsState>({
    assetsGrid: false,
    assetsGraph: false,
    auctionSummaryGrid: false,
    nopSummaryGraph: false,
    nopSummaryGrid: false,
    reserveBiddingSummaryGraph: false,
    reserveBiddingSummaryGrid: false,
    portfolioPositionGraphPT60M: false,
    portfolioPositionGridPT60M: false,
    portfolioPositionGraphPT30M: false,
    portfolioPositionGridPT30M: false,
    portfolioPositionGraphPT15M: false,
    portfolioPositionGridPT15M: false,
  });

  private readonly countryConfig$$ = new BehaviorSubject<CountryConfig>({
    currencyCode: 'EUR',
    currencySign: '€',
    defaultResolution: '',
    resolutions: [],
    tradingTimeOffset: 0,
    screens: [],
    contractTradingCloseTimeOffsets: [],
  });

  private readonly auctionsUiStateKey = 'auctionsUiState';
  private readonly sharedStatePeriodKey = 'sharedStatePeriod';
  private readonly sharedStateGranularityKey = 'sharedStateGranularity';
  private readonly sharedStateActiveScreenKey = 'sharedStateActiveScreen';
  private readonly sharedStateCollapsedCardsKey = 'collapsedCards';

  constructor(
    private localStorageService: LocalStorageService,
    private telemetryService: TelemetryService,
  ) {
    const auctionsUiStateFromStorage = this.localStorageService.getByKey<AuctionUILayoutMapping>(this.auctionsUiStateKey);
    if (auctionsUiStateFromStorage) {
      this.auctionsLayout$.set(auctionsUiStateFromStorage);
    }

    const statePeriodFromStorage = this.localStorageService.getByKey<PeriodType>(this.sharedStatePeriodKey);
    if (statePeriodFromStorage) {
      this.periodState$$.next(statePeriodFromStorage);
    }

    const granularityFromStorage = this.localStorageService.getByKey<GranularityType>(this.sharedStateGranularityKey);
    if (granularityFromStorage) {
      this.granularityState$$.next(granularityFromStorage);
    }

    const activeScreenFromStorage = this.localStorageService.getByKey<CardToggleGroupType | undefined>(this.sharedStateActiveScreenKey);
    if (activeScreenFromStorage) {
      this.activeScreen$$.next(activeScreenFromStorage);
    }

    const collapsedCardsFromStorage = this.localStorageService.getByKey<CollapsedCardsState>(this.sharedStateCollapsedCardsKey);
    if (collapsedCardsFromStorage) {
      this.collapsedCardsState$$.next(collapsedCardsFromStorage);
    }
  }

  public get activeScreenState(): CardToggleGroupType {
    return this.activeScreen$$.value;
  }

  public get activeScreenState$(): Observable<CardToggleGroupType> {
    return this.activeScreen$$.asObservable();
  }

  public get periodState(): PeriodType {
    return this.periodState$$.value;
  }

  public get periodState$(): Observable<PeriodType> {
    return this.periodState$$.asObservable();
  }

  public get currencyState(): string {
    return this.currencyState$$.value;
  }

  public get currencyState$(): Observable<string> {
    return this.currencyState$$.asObservable();
  }

  public get granularityState(): GranularityType {
    return this.granularityState$$.value;
  }

  public get granularityState$(): Observable<GranularityType> {
    return this.granularityState$$.asObservable();
  }

  public get timeZoneState(): string {
    return this.timeZoneState$$.value;
  }

  public get timeZoneState$(): Observable<string> {
    return this.timeZoneState$$.asObservable();
  }

  public get dateTimeLocaleState(): string {
    return this.dateTimeLocaleState$$.value;
  }

  public get dateTimeLocaleState$(): Observable<string> {
    return this.dateTimeLocaleState$$.asObservable();
  }

  public get collapsedCardsState(): CollapsedCardsState {
    return this.collapsedCardsState$$.value;
  }

  public get collapsedCardsState$(): Observable<CollapsedCardsState> {
    return this.collapsedCardsState$$.asObservable();
  }

  public get countryConfigState(): CountryConfig {
    return this.countryConfig$$.value;
  }

  public get countryConfigState$(): Observable<CountryConfig> {
    return this.countryConfig$$.asObservable();
  }

  public setAuctionsLayout(prop: AuctionUIViewProperties, layout: AuctionUILayout): void {
    this.auctionsLayout$.update((prev) => ({
      ...prev,
      [prop]: layout,
    }));

    this.localStorageService.setValueForKey(
      this.auctionsUiStateKey,
      this.auctionsLayout$(),
    );
  }

  public setActiveScreenState(screen: CardToggleGroupType): void {
    this.activeScreen$$.next(screen);

    this.localStorageService.setValueForKey(
      this.sharedStateActiveScreenKey,
      screen,
    );
  }

  public setCurrencyState(state: string): void {
    this.currencyState$$.next(state);
  }

  public setGranularityState(state: GranularityType): void {
    this.granularityState$$.next(state);
    this.localStorageService.setValueForKey(
      this.sharedStateGranularityKey,
      state,
    );
  }

  public setPeriodState(state: PeriodType): void {
    this.periodState$$.next(state);
    this.localStorageService.setValueForKey(
      this.sharedStatePeriodKey,
      state,
    );
  }

  public setTimeZoneState(state: string): void {
    this.timeZoneState$$.next(state);
  }

  public setDateTimeLocaleState(locale: string): void {
    this.dateTimeLocaleState$$.next(locale);
  }

  public setCollapsedCardState(cardName: keyof CollapsedCardsState, cardState: boolean): void {
    const newState: CollapsedCardsState = {
      ...this.collapsedCardsState$$.value,
      [cardName]: cardState,
    };

    this.collapsedCardsState$$.next(newState);

    this.localStorageService.setValueForKey(
      this.sharedStateCollapsedCardsKey,
      newState,
    );

    this.telemetryService.logEvent(TelemetryEvents.ExpandCollapseCardClicked, {
      cardName,
      hasBeenExpanded: !cardState,
    });
  }

  public setCountryConfigState(config: CountryConfig): void {
    this.countryConfig$$.next(config);
  }
}
