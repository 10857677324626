import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';

import { TimeSeriesResolution } from '@bradyplc/brady.powerdesk.api.internal.mfrr.contracts';

import { map } from 'rxjs/operators';

import { TelemetryEvents } from '@core/models/telemetry.enum';
import { TelemetryService } from '@core/services/telemetry.service';
import { GranularityType } from '@core/state-store/models/granularity.model';
import { PeriodType } from '@core/state-store/models/period.model';
import { UIStateStore } from '@core/state-store/ui-state-store.service';

import { SetPointsFacadeService } from '../../../set-points-sidenav/services/set-points-facade.service';
import { SubmitOrdersToTSOFacadeService } from '../../services/submit-orders-to-tso-facade.service';

@Component({
  selector: 'app-action-bar-container',
  templateUrl: './action-bar-container.component.html',
  styleUrls: ['./action-bar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionBarContainerComponent {
  @Input() editSetPointsVisible = false;
  @Input() editSetPointsEnabled = false;
  @Input() deactivateOrdersButtonVisible = false;

  public datePeriods = ['Today', 'Tomorrow'];
  public timePeriods$ = this.uiStateStore.countryConfigState$.pipe(
    map(({ resolutions }) => resolutions.map((r) => this.timeseriesResolutionToButtonLabelTypePair(r))),
  );

  public submittingToTSO: boolean = false;

  constructor(
    public setPointsFacade: SetPointsFacadeService,
    public uiStateStore: UIStateStore,
    private submitOrdersToTSOFacadeService: SubmitOrdersToTSOFacadeService,
    private cdr: ChangeDetectorRef,
    private telemetryService: TelemetryService,
  ) { }

  public onChangeUpdateUIGranularityStore(granularityType: GranularityType): void {
    this.telemetryService.logEvent(TelemetryEvents.ResolutionTypeFilterChanged, { resolutionType: granularityType });
    this.uiStateStore.setGranularityState(granularityType);
  }

  public onToggleSetPointsHandler(): void {
    this.telemetryService.logEvent(TelemetryEvents.ReserveEditSetPointsClicked, {
      expandedSetPointsPanel: !this.setPointsFacade.showPanel,
    });
    this.setPointsFacade.togglePanel(!this.setPointsFacade.showPanel);
  }

  public onChangeUpdateUIPeriodStore(periodType: PeriodType): void {
    this.telemetryService.logEvent(TelemetryEvents.PeriodTypeFilterChanged, { periodType });
    this.uiStateStore.setPeriodState(periodType);
  }

  public onSubmitToTSO(): void {
    if (this.submittingToTSO) {
      return;
    }

    this.submittingToTSO = true;
    this.cdr.markForCheck();

    const { timeZoneState, periodState } = this.uiStateStore;

    this.telemetryService.logEvent(TelemetryEvents.SubmittedToTSO, { timeZoneState, periodState });

    this.submitOrdersToTSOFacadeService.submit(timeZoneState, periodState).subscribe({
      next: () => {
        this.submittingToTSO = false;
        this.cdr.markForCheck();
      },
      error: () => {
        this.submittingToTSO = false;
        this.cdr.markForCheck();
      },
    });
  }

  public toLowerCase(value: string): string {
    return value.toLowerCase();
  }

  private timeseriesResolutionToButtonLabelTypePair(
    resolutoin: TimeSeriesResolution | string,
  ): { displayValue: string; granularity: NonNullable<GranularityType> } {
    switch (resolutoin) {
      case TimeSeriesResolution.PT15M:
        return { displayValue: '1/4 H', granularity: '15m' };
      case TimeSeriesResolution.PT30M:
        return { displayValue: '1/2 H', granularity: '30m' };
      case TimeSeriesResolution.PT60M:
      default:
        return { displayValue: '1 H', granularity: '1h' };
    }
  }
}
