import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public getByKey<T>(key: string): T | null {
    const value = localStorage.getItem(key);

    if (value) {
      try {
        return JSON.parse(value) as T;
      } catch (e) {
        return null;
      }
    }

    return null;
  }

  public setValueForKey<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public removeByKey(key: string): void {
    localStorage.removeItem(key);
  }

  public removeAllKeys(): void {
    localStorage.clear();
  }
}
