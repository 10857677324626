import { Injectable } from '@angular/core';

import {
  Observable,
  throwError,
} from 'rxjs';

import { UtilsDateTimeService } from '@core/services/utils/utils-date-time.service';
import { PeriodType } from '@core/state-store/models/period.model';

import { AssetHierarchyFacadeService } from '../../asset-hierarchy/services/asset-hierarchy-facade.service';
import { PortfolioHierarchyFacadeService } from '../../portfolio-hierarchy/services/portfolio-hierarchy-facade.service';

import { SubmitOrdersToTSOService } from './submit-orders-to-tso.service';

@Injectable({
  providedIn: 'root',
})
export class SubmitOrdersToTSOFacadeService {
  constructor(
    private assetHierarchyFacade: AssetHierarchyFacadeService,
    private portfolioHierarchyFacade: PortfolioHierarchyFacadeService,
    private utilsDateTimeService: UtilsDateTimeService,
    private submitOrdersToTSOService: SubmitOrdersToTSOService,
  ) {
  }

  public submit(timeZone: string, period: PeriodType): Observable<void> {
    const { getSelectedAssetGroup } = this.assetHierarchyFacade;
    const { selectedPortfolioHierarchy } = this.portfolioHierarchyFacade;

    if (!getSelectedAssetGroup || !selectedPortfolioHierarchy) {
      return throwError(() => new Error('No selected hierarchy.'));
    }

    const { startDateTime, endDateTime } = this.utilsDateTimeService.getStartAndEndDateTime(period, timeZone, true);

    return this.submitOrdersToTSOService.submit({
      assetGroupId: getSelectedAssetGroup.id,
      start: startDateTime.toISO(),
      end: endDateTime.toISO(),
      portfolioId: selectedPortfolioHierarchy.portfolioId as string,
    });
  }
}
