@if (selectedCountry) {
  <div class="country">
    @if (selectedCountry.countryId === 'ISEM') {
      <div class="country__inner">
        <img src="/assets/img/countries-flags/gb.svg" alt="Flag"class="country__flag"/>
      </div>
      <div class="country__inner country__inner_isem">
        <img src="/assets/img/countries-flags/ie.svg" alt="Flag"class="country__flag country__flag_isem"/>
      </div>
    } @else {
      <div class="country__inner">
        <img src="/assets/img/countries-flags/{{ (selectedCountry.countryId) | lowercase }}.svg"
          alt="Flag"
          class="country__flag"/>
      </div>
    }

    <mat-select data-testid="portfolioHierarchy-dropdown-country"
      hideSingleSelectionIndicator
      [(ngModel)]="selectedCountry"
      (ngModelChange)="onChangeCountry($event)">
      @for (country of (portfolioHierarchy ?? []); track country) {
        <mat-option
        [value]="country">{{country.name}}</mat-option>
      }
    </mat-select>
  </div>

  <mat-select data-testid="portfolioHierarchy-dropdown-deliveryArea"
      hideSingleSelectionIndicator
      [(ngModel)]="selectedDeliveryArea"
      (ngModelChange)="onChangeDeliveryArea($event)">
      @for (deliveryArea of selectedCountry.deliveryAreas; track deliveryArea) {
        <mat-option
        [value]="deliveryArea">{{deliveryArea.name}}</mat-option>
      }
  </mat-select>

  <mat-select data-testid="portfolioHierarchy-dropdown-portfolio"
    hideSingleSelectionIndicator
    [ngModel]="selectedPortfolio"
    (ngModelChange)="onChangePortfolio($event)">
    @for (portfolio of (selectedDeliveryArea?.portfolios ?? []); track portfolio) {
      <mat-option
      [value]="portfolio">{{portfolio.name}}</mat-option>
    }
  </mat-select>
}
