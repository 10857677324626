import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { DateTime, Settings } from 'luxon';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

type GlobalWithLuxon = (
  Window
  & typeof globalThis
  & {
    setLuxonNow: (date: number | string) => void;
    resetLuxonNow: () => void;
  }
);

// Save a reference of the original Luxon now() method
const luxonNowRef = Settings.now;

(window as GlobalWithLuxon).setLuxonNow = (date: number | string): void => {
  const now = typeof date === 'number'
    ? date
    : DateTime.fromISO(date).toMillis();

  // mock Luxon's now() method to return a specific date using timestamp or an ISO string
  Settings.now = () => now;
};

(window as GlobalWithLuxon).resetLuxonNow = () => {
  // restore Lixon's now() method from the saved reference
  Settings.now = luxonNowRef;
};

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
