import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';

import { Observable } from 'rxjs';

import { ErrorModel } from '@core/models/error.model';
import { LoggedUserService } from '@core/services/logged-user.service';

@Component({
  selector: 'app-error-container',
  templateUrl: './error-container.component.html',
  styleUrls: ['./error-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorContainerComponent implements OnInit {
  public error$: Observable<ErrorModel> = this.loggedUserService.error$;

  constructor(private loggedUserService: LoggedUserService) {
  }

  public ngOnInit(): void {
    setTimeout(() => this.loggedUserService.logout(), 10000);
  }
}
