<footer class="footer">
  <div class="footer__text">
    {{ copyright }} | PowerDesk Version {{ bradyVersion }}
  </div>

  <div class="footer__support">
    <mat-icon class="footer__icon">contact_support</mat-icon>

    <span>Contact support</span>
  </div>
</footer>
