@if (header) {
  <header>
    <h3 class="header">{{ header }}</h3>
  </header>
}

<div class="button-group">
  @for (item of items; track item.id) {
    <div
      class="button"
      [attr.data-testid]="'inbox-button-' + item.text | lowercase"
      matRipple
      [class.button--selected]="selectedItem?.id === item?.id"
      (click)="onSelect(item)"
      (keydown.enter)="onSelect(item)"
      [attr.tabindex]= "0">
      @if (selectedItem?.id === item?.id) {
        <mat-icon>check_circle</mat-icon>
      }
      <span class="button__text">{{ item.text }}</span>
    </div>
  }
</div>
