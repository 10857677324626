import { Injectable } from '@angular/core';

import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { FeedsEngineService } from '@core/feeds/feeds-engine.service';
import { FeedsService } from '@core/feeds/feeds.service';

import { InboxFilter } from '../models/inbox-filter';
import { InboxTypeEnum } from '../models/inbox-type.enum';
import { NotificationModel } from '../models/notification.model';

import { NotificationsApiService } from './notifications-api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsFacadeService {
  public isLoadingNotifications$: Observable<boolean> = this.feedsEngineService.isLoadingNotifications$;
  public pageSize = 10;

  public unseenNotificationsCount$?: Observable<number>;

  private inboxNotifications$$ = new BehaviorSubject<NotificationModel[]>([]);
  public inboxNotifications$ = this.inboxNotifications$$.asObservable();

  private fetchedPages = new Set<number>();
  private filters$$ = new BehaviorSubject<InboxFilter | null>(null);
  private allNotifications$ = this.feedsEngineService.getAllNotifications$();

  constructor(
    private feedsService: FeedsService,
    private feedsEngineService: FeedsEngineService,
    private notificationsApi: NotificationsApiService,
  ) {
    combineLatest([
      this.allNotifications$,
      this.filters$$,
    ]).pipe(
      map(([allNotifications, inboxFilter]) => {
        if (inboxFilter?.pageIndex === 1) {
          this.fetchedPages.clear();
          this.fetchedPages.add(1);
        }

        return this.getNotifications(
          allNotifications,
          inboxFilter?.inboxType ?? InboxTypeEnum.All,
          inboxFilter?.search ?? '',
          inboxFilter?.pageIndex ?? 1,
        );
      }),
    ).subscribe((notifications) => {
      this.inboxNotifications$$.next(notifications);
    });

    this.unseenNotificationsCount$ = this.allNotifications$.pipe(
      map((notifications) => notifications.reduce((all, current) => {
        const unseenChildren = current.children.filter((notification: NotificationModel) => !notification.isAcknowledged);
        const currentCount = (!current.isAcknowledged && current.target !== 'critical') ? 1 : 0;
        const total = all + currentCount + unseenChildren.length;

        return total > 99 ? 99 : total;
      }, 0)),
    );
  }

  public get currentCriticalNotifications$(): Observable<NotificationModel[]> {
    return this.allNotifications$.pipe(map((notifications) => (
      notifications.filter((n) => n.target === 'critical' && !n.isAcknowledged)
    )));
  }

  public toggleAcknowledgement(notification: NotificationModel): void {
    if (!notification.isAcknowledged) {
      this.notificationsApi.createAcknowledgement$(this.feedsService.personalFeedId, notification).subscribe();
    }
  }

  public changeInboxType(inboxType: InboxTypeEnum): void {
    this.filters$$.next({
      ...this.filters$$.value,
      pageIndex: 1,
      inboxType,
    });
  }

  public changeSearch(search: string | null): void {
    this.filters$$.next({
      ...this.filters$$.value,
      pageIndex: 1,
      search,
    });
  }

  public changeDate(date: { from?: DateTime | null; to?: DateTime | null }): void {
    this.filters$$.next({
      ...this.filters$$.value,
      pageIndex: 1,
      date,
    });
  }

  public changePage(page: number): void {
    if (this.fetchedPages.has(page)) {
      return;
    }

    this.fetchedPages.add(page);

    this.filters$$.next({
      ...this.filters$$.value,
      pageIndex: page,
    });
  }

  public resetInboxState(): void {
    this.filters$$.next({
      inboxType: InboxTypeEnum.All,
      date: null,
      pageIndex: 1,
      search: null,
    });
  }

  private getNotifications(
    allNotifications: NotificationModel[],
    inboxType: InboxTypeEnum,
    search: string | null,
    pageIndex: number,
  ): NotificationModel[] {
    let filteredNotifications: NotificationModel[] = allNotifications;

    if (search) {
      filteredNotifications = filteredNotifications.filter((x) => x.title.toLowerCase().indexOf(search.toLowerCase()) > -1
        || x.content.toLowerCase().indexOf(search.toLowerCase()) > -1);
    }

    const begin = ((pageIndex - 1) * this.pageSize);
    const end = begin + this.pageSize;
    filteredNotifications = filteredNotifications.slice(0, end);

    return filteredNotifications;
  }
}
