import { Injectable } from '@angular/core';

import {
  DateTime,
  IANAZone,
} from 'luxon';

import { NopSummaryCountryGroup } from '../../../shared/models/nop-summary-country-group.interface';
import { PeriodType } from '../../state-store/models/period.model';

@Injectable({
  providedIn: 'root',
})
export class UtilsDateTimeService {
  public getDateTime(period: PeriodType, timeZone: string): DateTime {
    switch (period) {
      case 'Today':
        return DateTime.local().setZone(timeZone);

      case 'Tomorrow':
        return DateTime.local().setZone(timeZone).plus({ days: 1 });

      default:
        return DateTime.local().setZone(timeZone);
    }
  }

  public getStartAndEndDateTime(
    period: PeriodType,
    timeZone: string,
    toUTC: boolean = false,
  ): { startDateTime: DateTime; endDateTime: DateTime } {
    const dateTime = this.getDateTime(period, timeZone);
    let startDateTime = dateTime.startOf('day');
    let endDateTime = dateTime.plus({ days: 1 }).startOf('day');

    if (toUTC) {
      startDateTime = startDateTime.toUTC();
      endDateTime = endDateTime.toUTC();
    }

    return { startDateTime, endDateTime };
  }

  public getFormattedDateTime(
    format: string,
    timeZone: string,
    locale?: string,
    date: string = DateTime.local().toString(),
  ): string {
    let dateTime = DateTime.fromISO(date).setZone(timeZone);
    if (locale) {
      dateTime = dateTime.setLocale(locale);
    }

    return dateTime.toFormat(format);
  }

  public isDateRangePartOfTodayOrTomorrow(
    minDate: Date | string | undefined,
    maxDate: Date | string | undefined,
    timeZone: string,
  ): boolean {
    if (!minDate || !maxDate) {
      return false;
    }
    const minDateTime = (typeof minDate === 'string' ? DateTime.fromISO(minDate) : DateTime.fromJSDate(minDate)).setZone(timeZone);
    const maxDateTime = (typeof maxDate === 'string' ? DateTime.fromISO(maxDate) : DateTime.fromJSDate(maxDate)).setZone(timeZone);

    const todayDateTime = this.getDateTime('Today', timeZone);
    const todayStartDateTime = todayDateTime.startOf('day');
    const tomorrowEndDateTime = todayDateTime.plus({ days: 2 }).startOf('day');

    return minDateTime < tomorrowEndDateTime && maxDateTime > todayStartDateTime;
  }

  public getCountryZoneOffsets(countryGroups: NopSummaryCountryGroup[]): { countryId: string; zone: number }[] {
    return countryGroups.map(({ countryId, timeZone }) => {
      const zone = IANAZone.create(timeZone);
      const zonedTimestamp = DateTime.now().setZone(zone).toMillis();
      return { countryId, zone: zone.offset(zonedTimestamp) };
    });
  }
}
