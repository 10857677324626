import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';

import { ErrorDialogService } from '@shared/error-dialog/services/error-dialog.service';

import { ProblemDetails } from './models/problem-details.interface';
import { SnackbarService } from './services/snackbar-service';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  constructor(
    private logger: NGXLogger,
    private snackbarService:SnackbarService,
    private errorDialogService: ErrorDialogService,
  ) {}

  public handleError(err: HttpErrorResponse, openNotification = false): Observable<never> {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.logger.error(errorMessage);

    if (openNotification) {
      this.handleHttpErrorResponseAndOpenNotification(err);
    }

    return throwError(() => new Error(errorMessage));
  }

  public handleHttpErrorResponseAndOpenNotification(err : HttpErrorResponse):void {
    switch (err.status) {
      case 429: {
        if (err.url?.includes('/results-retrievals')) {
          this.snackbarService.showSnackbar(`Please wait ${this.getRetryAfter(err)}before attempting to retrieve the results again.`, false);
        } else {
          this.snackbarService.showSnackbar('Too many requests. Please try again later.', false);
        }
        break;
      }

      case 409: {
        const problemDetails = err.error as ProblemDetails;
        this.errorDialogService.showErrorDialog({
          title: '',
          content: problemDetails?.detail ?? 'Validation error',
          type: 'validation',
        });
        break;
      }

      case 403:
        this.snackbarService.showSnackbar(`You don't have permission to perform this action`, false);
        break;

      default:
        this.snackbarService.showSnackbar('This action could not be performed. Please contact Brady support if the issue persists.', false);
    }
  }

  private getRetryAfter(err: HttpErrorResponse): string {
    const retryAfterSeconds = err.headers.get('Retry-After');

    if (!retryAfterSeconds) {
      return '';
    }

    const seconds = parseInt(retryAfterSeconds);
    return `${seconds} second${seconds !== 1 ? 's' : ''} `;
  }
}
