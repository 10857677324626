import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const directNavigationGuard = () : boolean => {
  const router = inject(Router);

  const currentNavigation = router.getCurrentNavigation();
  if (currentNavigation?.id === 1) { // id === 1 would indicate that the user has accessed it directly via browser URL/page reload, not by redirection via router.navigate
    void router.navigate(['']);
    return false;
  }
  return true;
};
