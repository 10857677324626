import { Injectable } from '@angular/core';

import { TimeSeriesResolution } from '@bradyplc/brady.powerdesk.api.internal.mfrr.contracts';

import { BehaviorSubject } from 'rxjs';

import { UIStateStore } from '@core/state-store/ui-state-store.service';

import { CardToggleGroupType } from '../../modules/card-toggle-group/models/card-toggle-group.type';
import { ScrollConfigPerResolution } from '../models/grid-chart-sync.models';
import {
  COLUMN_DEFINITIONS,
  getColumnWidth,
  getXAxisLeft,
} from '../utils/common-grid-definitions';

@Injectable({
  providedIn: 'root',
})
export class GridChartSyncHelperService {
  constructor(private uiStateStore: UIStateStore) {}
  public readonly gridGraphAlignmentPadding = 25;

  private totalPastColsWidth$$ = new BehaviorSubject<Record<string, number>>({});
  public totalPastColsWidth$ = this.totalPastColsWidth$$.asObservable();

  private isReadyGridGraph$$ = new BehaviorSubject<Record<string, boolean>>({});
  public isReadyGridGraph$ = this.isReadyGridGraph$$.asObservable();

  public setTotalPastColWidth(resolution: string, width: number): void {
    const grid = resolution === '' ? this.uiStateStore.activeScreenState : resolution;
    this.totalPastColsWidth$$.next({ ...this.totalPastColsWidth$$.value, [grid]: width });
  }

  public setIsReadyGridGraph(id: string, isReady: boolean): void {
    this.isReadyGridGraph$$.next({ ...this.isReadyGridGraph$$.value, [id]: isReady });
  }

  public reset(): void {
    this.isReadyGridGraph$$.next({});
    this.totalPastColsWidth$$.next({});
  }

  public getScrollConfigPerResolution(activeScreen: CardToggleGroupType, totalChartColumns: number): ScrollConfigPerResolution[] {
    const isNopSummaryScreen = activeScreen === 'nop-summary';
    const columnWidthFor1hGrid = getColumnWidth(60);
    const xAxisLeftFor1hGrid = getXAxisLeft(60);

    return [
      {
        resolution: isNopSummaryScreen ? TimeSeriesResolution.PT60M : 60,
        scrollablePlotArea: (columnWidthFor1hGrid * totalChartColumns) + this.gridGraphAlignmentPadding,
        xAxisWidth: isNopSummaryScreen
          ? columnWidthFor1hGrid * totalChartColumns - 4
          : columnWidthFor1hGrid * totalChartColumns - 3,
        xAxisLeft: xAxisLeftFor1hGrid,
      },
      {
        resolution: isNopSummaryScreen ? TimeSeriesResolution.PT30M : 30,
        scrollablePlotArea: (COLUMN_DEFINITIONS.width * totalChartColumns) + this.gridGraphAlignmentPadding,
        xAxisWidth: COLUMN_DEFINITIONS.width * totalChartColumns,
        xAxisLeft: 20,
      },
      {
        resolution: isNopSummaryScreen ? TimeSeriesResolution.PT15M : 15,
        scrollablePlotArea: COLUMN_DEFINITIONS.width * totalChartColumns,
        xAxisWidth: COLUMN_DEFINITIONS.width * totalChartColumns,
        xAxisLeft: 20,
      },
    ];
  }

  public getLeadingColumnsBeforeActivePeriod(resolution: string | null): number {
    let leadingColumnsBeforeActivePeriod = 2;

    if (resolution as TimeSeriesResolution.PT30M === TimeSeriesResolution.PT30M || resolution === '30m') {
      leadingColumnsBeforeActivePeriod = 4;
    }

    if (resolution as TimeSeriesResolution.PT15M === TimeSeriesResolution.PT15M || resolution === '15m') {
      leadingColumnsBeforeActivePeriod = 8;
    }

    return leadingColumnsBeforeActivePeriod;
  }

  public getActiveScrollingLeftPosition(resolution: string | null, graphOverlayWidth: number): number {
    const granularityMinutes = resolution === TimeSeriesResolution.PT60M ? 60 : 0;
    const columnWidth = getColumnWidth(granularityMinutes);
    return graphOverlayWidth - columnWidth * this.getLeadingColumnsBeforeActivePeriod(resolution);
  }
}
