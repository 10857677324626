import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { TelemetryEvents } from '@core/models/telemetry.enum';
import { TelemetryService } from '@core/services/telemetry.service';

import { ButtonToggleModel } from '@shared/button-toggle-group/models/button-toggle.model';

import { CardToggleGroupType } from '../../../card-toggle-group/models/card-toggle-group.type';
import { SelectableAssetGroupModel } from '../../models/selectable-asset-group.model';
import { SelectableAssetModel } from '../../models/selectable-asset.model';
import { SelectableGeneratorModel } from '../../models/selectable-generator.model';
import { AssetHierarchyFacadeService } from '../../services/asset-hierarchy-facade.service';

@Component({
  selector: 'app-asset-hierarchy',
  templateUrl: './asset-hierarchy.component.html',
  styleUrls: ['./asset-hierarchy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetHierarchyComponent implements OnInit {
  public get selectedAssetGroup$(): Observable<SelectableAssetGroupModel | undefined> {
    return this.assetHierarchyFacadeService.selectedAssetHierarchy$.pipe(
      map((assetGroups: SelectableAssetGroupModel[]) => assetGroups.find(
        (assetGroup: SelectableAssetGroupModel) => assetGroup.isSelected,
      )),
    );
  }

  public get assetGroups$(): Observable<ButtonToggleModel[] | undefined> {
    return this.assetHierarchyFacadeService.selectedAssetHierarchy$.pipe(
      switchMap((assetGroups: SelectableAssetGroupModel[]) => of(
        assetGroups.map((assetGroup) => ({
          id: assetGroup.id,
          text: assetGroup.name,
        } as ButtonToggleModel)),
      )),
    );
  }

  public get selectedAsset$(): Observable<SelectableAssetModel | undefined> {
    return this.selectedAssetGroup$.pipe(
      map((assetGroup: SelectableAssetGroupModel | undefined) => assetGroup?.assets.find(
        (asset: SelectableAssetModel) => asset.isSelected,
      )),
    );
  }

  public get assets$(): Observable<ButtonToggleModel[] | undefined> {
    if (this.selectedCardType === 'assets') {
      return this.assetHierarchyFacadeService.selectedAssetHierarchy$.pipe(
        switchMap((assetGroups: SelectableAssetGroupModel[]) => of(
          assetGroups.flatMap((assetGroup: SelectableAssetGroupModel) => assetGroup.assets.map((asset: SelectableAssetModel) => ({
            id: asset.id,
            text: asset.name,
          }))),
        )),
      );
    }

    return this.selectedAssetGroup$.pipe(
      switchMap((assetGroup: SelectableAssetGroupModel | undefined) => of(
        assetGroup?.assets.map((asset: SelectableAssetModel) => ({
          id: asset.id,
          text: asset.name,
        })),
      )),
    );
  }

  public get selectedGenerator$(): Observable<SelectableGeneratorModel | undefined> {
    return this.selectedAsset$.pipe(
      map((asset: SelectableAssetModel | undefined) => asset?.generators.find(
        (generator: SelectableGeneratorModel) => generator.isSelected,
      )),
    );
  }

  public get generators$(): Observable<ButtonToggleModel[] | undefined> {
    return this.selectedAsset$.pipe(
      switchMap((asset: SelectableAssetModel | undefined) => of(
        asset?.generators.map((s) => ({
          id: s.id,
          text: s.name,
        })),
      )),
    );
  }

  @Input({ required: true }) selectedCardType: CardToggleGroupType | undefined;

  constructor(
    public assetHierarchyFacadeService: AssetHierarchyFacadeService,
    private telemetryService: TelemetryService,
  ) { }

  public ngOnInit(): void {
    this.assetDefaultSelection();
  }

  public onSelectAssetGroup(assetGroupId: string): void {
    const selectedAssetHierarchyState = this.getSelectedAssetHierarchyState();

    this.deselectPreviousTree(selectedAssetHierarchyState);

    // Set the new selection
    const selectedAssetGroup: SelectableAssetGroupModel | undefined = selectedAssetHierarchyState.find((x) => x.id === assetGroupId);
    if (selectedAssetGroup) {
      selectedAssetGroup.isSelected = true;
    }

    this.telemetryService.logEvent(TelemetryEvents.ReserveAssetGroupSelect, { selectedAssetGroup: assetGroupId });
    this.updateSelectedAssetHierarchyState(selectedAssetHierarchyState);
  }

  private deselectPreviousTree(selectedAssetHierarchyState: SelectableAssetGroupModel[]): void {
    const previousSelectedAssetGroup: SelectableAssetGroupModel | undefined = selectedAssetHierarchyState.find((x) => x.isSelected);

    // Deselect previous tree
    if (previousSelectedAssetGroup) {
      previousSelectedAssetGroup.isSelected = false;
      previousSelectedAssetGroup.assets.forEach((asset) => {
        asset.isSelected = false;
        asset.generators.forEach((generator) => { generator.isSelected = false });
      });
    }
  }

  private getSelectedAssetHierarchyState(): SelectableAssetGroupModel[] {
    return structuredClone(this.assetHierarchyFacadeService.selectedAssetHierarchy);
  }

  public onSelectAsset(assetId: string): void {
    const selectedAssetHierarchyState = this.getSelectedAssetHierarchyState();

    if (this.selectedCardType === 'assets') {
      const assetGroup = selectedAssetHierarchyState.find((x) => x.assets.find((a) => a.id === assetId) != null);
      if (assetGroup) {
        this.deselectPreviousTree(selectedAssetHierarchyState);
        assetGroup.isSelected = true;
      } else return;
    }

    const selectedAssetGroup: SelectableAssetGroupModel | undefined = selectedAssetHierarchyState.find((x) => x.isSelected);
    if (!selectedAssetGroup) {
      return;
    }
    const previousSelectedAsset: SelectableAssetModel | undefined = selectedAssetGroup.assets.find((x) => x.isSelected);

    // Deselect previous tree
    if (previousSelectedAsset) {
      previousSelectedAsset.isSelected = false;
      previousSelectedAsset.generators.forEach(
        (x) => { x.isSelected = false },
      );
    }

    // Set the new selection
    const selectedAsset = selectedAssetGroup.assets.find(
      (x) => x.id === assetId,
    );
    if (selectedAsset) {
      selectedAsset.isSelected = true;
    }

    this.telemetryService.logEvent(TelemetryEvents.ReserveAssetSelect, { selectedAsset: assetId });
    this.updateSelectedAssetHierarchyState(selectedAssetHierarchyState);
  }

  public onSelectGenerator(generatorId: string): void {
    const selectedAssetHierarchyState = this.getSelectedAssetHierarchyState();
    const selectedAssetGroup: SelectableAssetGroupModel | undefined = selectedAssetHierarchyState.find((x) => x.isSelected);
    if (!selectedAssetGroup) {
      return;
    }
    const selectedAsset = selectedAssetGroup.assets.find((x) => x.isSelected);
    if (!selectedAsset) {
      return;
    }

    const previousSelectedGenerator = selectedAsset.generators.find((x) => x.isSelected);

    // Deselect previous
    if (previousSelectedGenerator) {
      previousSelectedGenerator.isSelected = false;
    }

    // Set the new selection
    const selectedGenerator = selectedAsset.generators.find((x) => x.id === generatorId);
    if (selectedGenerator) {
      selectedGenerator.isSelected = true;
    }

    this.telemetryService.logEvent(TelemetryEvents.ReserveGeneratorSelect, { selectedGenerator: generatorId });
    this.updateSelectedAssetHierarchyState(selectedAssetHierarchyState);
  }

  private updateSelectedAssetHierarchyState(
    newState: SelectableAssetGroupModel[],
  ): void {
    if (
      JSON.stringify(newState)
      !== JSON.stringify(this.assetHierarchyFacadeService.selectedAssetHierarchy)
    ) {
      this.assetHierarchyFacadeService.setSelectedAssetHierarchy(newState);
    }
  }

  private assetDefaultSelection(): void {
    if (this.selectedCardType === 'assets') {
      const selectedAssetHierarchyState = this.getSelectedAssetHierarchyState();
      if (selectedAssetHierarchyState && selectedAssetHierarchyState.length > 0) {
        const selectedAssetGroup = selectedAssetHierarchyState.find((x) => x.isSelected);
        if (selectedAssetGroup) {
          const selectedAsset = selectedAssetGroup.assets.find((x) => x.isSelected);
          if (!selectedAsset) {
            selectedAssetGroup.assets[0].isSelected = true;
            this.onSelectAsset(selectedAssetGroup.assets[0].id);
          } else {
            selectedAsset.generators.forEach((x) => { x.isSelected = false });
            this.onSelectAsset(selectedAsset.id);
          }
        }
      }
    }
  }
}
