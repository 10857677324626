import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  Subscription,
  take,
  timer,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressIndicatorService {
  private showProgress$$ = new BehaviorSubject<boolean>(false);
  private timerSubscription?: Subscription;
  private delayTime: number = 1000;

  constructor() {
    // Expose global function for QA to update delay itme
    (window as (
      Window
      & typeof globalThis
      & { setProgressIndicatorDelay: (delay: number) => void }
    )).setProgressIndicatorDelay = (delay: number): void => {
      this.delayTime = delay;
    };
  }

  public get showProgress$(): Observable<boolean> {
    return this.showProgress$$.asObservable();
  }

  public get showProgress(): boolean {
    return this.showProgress$$.value;
  }

  public displayProgressIndicator(autoHide: boolean = true): void {
    this.timerSubscription?.unsubscribe();

    this.showProgress$$.next(true);

    if (autoHide) {
      this.hideProgressIndicator();
    }
  }

  public hideProgressIndicator(): void {
    this.timerSubscription = timer(this.delayTime)
      .pipe(take(1))
      .subscribe(() => this.showProgress$$.next(false));
  }
}
