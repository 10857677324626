import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import {
  DeactivateOrdersButtonComponent,
} from './components/deactivate-orders-button/deactivate-orders-button.component';
import {
  DeactivateOrdersConfirmationDialogComponent,
} from './components/deactivate-orders-confirmation-dialog/deactivate-orders-confirmation-dialog.component';

@NgModule({
  declarations: [
    DeactivateOrdersButtonComponent,
    DeactivateOrdersConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
  ],
  exports: [
    DeactivateOrdersButtonComponent,
  ],
})
export class ManageOrdersModule {
}
