import { Injectable } from '@angular/core';

import {
  CountryResult,
  DeliveryAreaResult,
  PortfolioResult,
} from '@bradyplc/brady.powerdesk.api.internal.systemreferencedata.contracts';

import { environment } from '@env/environment';
import {
  BehaviorSubject,
  Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { ClaimsModel } from '@core/models/claimsModel';
import { TelemetryEvents } from '@core/models/telemetry.enum';
import { LocalStorageService } from '@core/services/local-storage.service';
import { LoggedUserService } from '@core/services/logged-user.service';
import { TelemetryService } from '@core/services/telemetry.service';
import { UtilsGranularityService } from '@core/services/utils/utils-granularity.service';
import { UIStateStore } from '@core/state-store/ui-state-store.service';

import { SelectedPortfolioHierarchyModel } from '../models/selected-portfolio-hierarchy.model';

import { PortfolioHierarchyApiService } from './portfolio-hierarchy-api.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioHierarchyFacadeService {
  private portfolioHierarchy$$ = new BehaviorSubject<CountryResult[]>([]);
  public portfolioHierarchy$ = this.portfolioHierarchy$$.asObservable();

  private selectedPortfolioHierarchy$$ = new BehaviorSubject<SelectedPortfolioHierarchyModel | null>(null);
  public selectedPortfolioHierarchy$ = this.selectedPortfolioHierarchy$$.asObservable();

  private readonly localStorageSelectedPortfolioHierarchyKey = 'SelectedPortfolioHierarchyKey';
  private _isFirstSelectedPortfolioHierarchy = false;

  constructor(
    private loggedUserService: LoggedUserService,
    private localStorageService: LocalStorageService,
    private portfolioHierarchyApiService: PortfolioHierarchyApiService,
    private telemetryService: TelemetryService,
    private uiStateStore: UIStateStore,
    private utilsGranularity: UtilsGranularityService,
  ) {
    this.loadSelectedPortfolioHierarchy();
  }

  public get portfolioHierarchy(): CountryResult[] {
    return this.portfolioHierarchy$$.value;
  }

  public get selectedPortfolioHierarchy(): SelectedPortfolioHierarchyModel | null {
    return this.selectedPortfolioHierarchy$$.value;
  }

  public get selectedPortfolio$(): Observable<string | null | undefined> {
    return this.selectedPortfolioHierarchy$.pipe(map((x) => x?.portfolioId));
  }

  public get selectedPortfolioId(): string {
    return this.selectedPortfolioHierarchy$$.value?.portfolioId ?? '';
  }

  public get isFirstSelectedPortfolioHierarchy(): boolean {
    return this._isFirstSelectedPortfolioHierarchy;
  }

  public loadPortfolioHierarchy(): void {
    const userTenantId = (<ClaimsModel>(this.loggedUserService.claims[environment.auth0Namespace]))['tenantId'] as string;

    this.portfolioHierarchyApiService
      .getPortfolioHierarchy$(userTenantId)
      .subscribe((portfolioHierarchy) => {
        this.portfolioHierarchy$$.next(portfolioHierarchy);
        if (!this.selectedPortfolioHierarchy$$.value) {
          this.selectFirstPortfolioHierarchy();
        } else {
          this.restoreSelectedPortfolioHierarchy(this.selectedPortfolioHierarchy$$.value);
        }
      });
  }

  public setSelectedPortfolioHierarchy(selectedPortfolioHierarchy: SelectedPortfolioHierarchyModel): void {
    this.localStorageService.setValueForKey(this.localStorageSelectedPortfolioHierarchyKey, selectedPortfolioHierarchy);
    this._isFirstSelectedPortfolioHierarchy = false;
    this.storeCountryConfig(this.portfolioHierarchy$$.value.find((c) => c.id === selectedPortfolioHierarchy.countryId));
    this.telemetryService.logEvent(TelemetryEvents.PortfolioHierarchyChanged, {
      previousProfolioHierarchy: this.selectedPortfolioHierarchy,
      selectedProfolioHierarchy: selectedPortfolioHierarchy,
    });

    this.telemetryService.participantId = selectedPortfolioHierarchy.participantId;
    this.selectedPortfolioHierarchy$$.next(selectedPortfolioHierarchy);
  }

  private loadSelectedPortfolioHierarchy(): void {
    const selectedPortfolioHierarchy = this.localStorageService
      .getByKey<SelectedPortfolioHierarchyModel>(this.localStorageSelectedPortfolioHierarchyKey);

    if (selectedPortfolioHierarchy) {
      this._isFirstSelectedPortfolioHierarchy = true;
      this.selectedPortfolioHierarchy$$.next(selectedPortfolioHierarchy);
    }
  }

  private selectFirstPortfolioHierarchy(): void {
    const firstCountry: CountryResult | undefined = this.portfolioHierarchy$$.value[0];
    const firstDeliveryArea: DeliveryAreaResult | undefined = firstCountry?.deliveryAreas[0];
    const firstPortfolio: PortfolioResult | undefined = firstDeliveryArea?.portfolios[0];
    this.setSelectedPortfolioHierarchy({
      countryId: firstCountry?.id,
      deliveryAreaId: firstDeliveryArea?.id,
      portfolioId: firstPortfolio?.id,
      participantId: firstPortfolio?.participantId,
      deliveryAreaEicCode: firstDeliveryArea?.eicCode,
      nonPrefixedPortfolioId: firstPortfolio?.portfolioId,
      nonPrefixedParticipantId: firstPortfolio?.participant?.participantId,
    });
  }

  private restoreSelectedPortfolioHierarchy(selection: SelectedPortfolioHierarchyModel): void {
    const country: CountryResult | undefined = this.portfolioHierarchy$$.value.find(({ id }) => id === selection.countryId);

    if (!country) {
      this.selectFirstPortfolioHierarchy();
      return;
    }

    const deliveryArea: DeliveryAreaResult | undefined = country.deliveryAreas.find(
      ({ id }) => id === selection.deliveryAreaId,
    ) || country.deliveryAreas[0];

    const portfolio: PortfolioResult | undefined = deliveryArea?.portfolios.find(
      ({ id }) => id === selection.portfolioId,
    ) || deliveryArea?.portfolios[0];

    this.setSelectedPortfolioHierarchy({
      countryId: country.id,
      deliveryAreaId: deliveryArea?.id,
      portfolioId: portfolio?.id,
      participantId: portfolio?.participantId,
      deliveryAreaEicCode: deliveryArea?.eicCode,
      nonPrefixedPortfolioId: portfolio?.portfolioId,
      nonPrefixedParticipantId: portfolio?.participant?.participantId,
    });
  }

  private storeCountryConfig(selectedCountry: CountryResult | undefined) {
    if (!selectedCountry) {
      return;
    }

    const resolutions = selectedCountry.timeResolutions
      .map((r) => r.resolution)
      .sort((a, b) => b.localeCompare(a));

    this.uiStateStore.setCountryConfigState({
      currencyCode: selectedCountry.currencyCode,
      currencySign: selectedCountry.currencySign,
      resolutions,
      defaultResolution: selectedCountry.defaultResolution,
      tradingTimeOffset: selectedCountry.tradingTimeOffset,
      screens: selectedCountry.screens,
      contractTradingCloseTimeOffsets: selectedCountry.contractTradingCloseTimeOffsets,
    });

    this.uiStateStore.setTimeZoneState(selectedCountry.timeZone);

    // Fall back to default resolution if the current one is not supported
    if (!resolutions.includes(this.utilsGranularity.granularityTypeToTimeseriesResolution(this.uiStateStore.granularityState))) {
      const granularity = this.utilsGranularity.timeseriesResolutionGranularityMap[selectedCountry.defaultResolution];
      this.uiStateStore.setGranularityState(granularity);
    }
  }
}
