<mat-icon class="error-banner__icon">error</mat-icon>
<h4 class="error-banner__title text-md">{{title}}</h4>
<div class="text-md" [innerHtml]="content"></div>
@if (isMultipleBannersMode()) {
  <div class="error-banner__counter">
    <div class="text-md" [innerHtml]="getCounterText()" class="error-banner__counter-text"></div>
    <div>
      <button mat-icon-button
        class="arrow-button"
        [disabled]="isPreviousButtonDisabled"
        (click)="onClickPreviousBanner()"
        >
        <mat-icon fontIcon="keyboard_arrow_left"></mat-icon>
      </button>
      <button mat-icon-button
        class="arrow-button"
        [disabled]="isNextButtonDisabled"
        (click)="onClickNextBanner()"
        >
        <mat-icon fontIcon="keyboard_arrow_right"></mat-icon>
      </button>
    </div>
  </div>
}

