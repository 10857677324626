import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TelemetryEvents } from '@core/models/telemetry.enum';
import { LoggedUserService } from '@core/services/logged-user.service';
import { TelemetryService } from '@core/services/telemetry.service';
import { UtilsDateTimeService } from '@core/services/utils/utils-date-time.service';
import { UIStateStore } from '@core/state-store/ui-state-store.service';

import { InboxPanelService } from '../../../notifications/services/inbox-panel.service';
import { NotificationsFacadeService } from '../../../notifications/services/notifications-facade.service';
import { SetPointsFacadeService } from '../../../set-points-sidenav/services/set-points-facade.service';

@Component({
  selector: 'app-bar-container',
  templateUrl: './app-bar-container.component.html',
  styleUrls: ['./app-bar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBarContainerComponent implements OnInit {
  public date$?: Observable<string>;
  public avatarPhotoUrl?: string;
  public avatarInitials?: string;

  public unseenNotificationsCount$?: Observable<number> = this.notificationsFacade.unseenNotificationsCount$;

  constructor(
    public inboxPanel: InboxPanelService,
    private utilsDateTimeService: UtilsDateTimeService,
    private uiStateStore: UIStateStore,
    private notificationsFacade: NotificationsFacadeService,
    private loggedUserService: LoggedUserService,
    private setPointsPanelFacade: SetPointsFacadeService,
    private telemetryService: TelemetryService,
  ) {
  }

  public ngOnInit(): void {
    this.date$ = this.uiStateStore.timeZoneState$.pipe(
      map((timeZone: string) => {
        const formattedDateTime = this.utilsDateTimeService.getFormattedDateTime('cccc d MMM yyyy', timeZone);
        return `Today, ${formattedDateTime}`;
      }),
    );

    const { loggedUser } = this.loggedUserService;
    if (loggedUser.pictureUri) {
      this.avatarPhotoUrl = loggedUser.pictureUri;
    } else {
      this.avatarInitials = loggedUser.initials;
    }
  }

  public onClickSignOut(): void {
    this.loggedUserService.logout();
  }

  public onClickInbox(): void {
    if (this.setPointsPanelFacade.showPanel) {
      this.setPointsPanelFacade.togglePanel(false);
    }

    this.telemetryService.logEvent(TelemetryEvents.InboxClicked, { expandedInbox: !this.inboxPanel.showPanel });
    this.inboxPanel.togglePanel(!this.inboxPanel.showPanel);
  }
}
