/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CountryResult } from '@bradyplc/brady.powerdesk.api.internal.systemreferencedata.contracts';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortfolioHierarchyApiService {
  constructor(private httpClient: HttpClient) {}

  public getPortfolioHierarchy$(tenantId: string): Observable<CountryResult[]> {
    const result = this.httpClient.post<CountryResult[]>(environment.portfolioHierarchyUrl, { tenantId });
    return result;
  }
}
