import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ClaimsModel } from '../models/claimsModel';
import { LoggedUserService } from '../services/logged-user.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private readonly tenantRequiredUrls: string[] = [
    environment.submitOrdersToTSOUrl,
    environment.saveMfrrPriceUrl,
    environment.updateSetPointsUrl,
    environment.settingsApiUrl,
  ];

  constructor(private loggedUser: LoggedUserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isTenantRequired = this.tenantRequiredUrls.some((url) => request.url.startsWith(url));

    if (this.loggedUser.accessToken && isTenantRequired) {
      const claims = this.loggedUser.claims[environment.auth0Namespace] as ClaimsModel;
      return next.handle(
        request.clone({
          setHeaders: { TenantId: claims['tenantId'] as string },
        }),
      );
    }

    return next.handle(request);
  }
}
