import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ManageOrdersFacadeService } from '../../services/manage-orders-facade.service';

@Component({
  selector: 'app-deactivate-orders-confirmation-dialog',
  templateUrl: './deactivate-orders-confirmation-dialog.component.html',
  styleUrls: ['./deactivate-orders-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeactivateOrdersConfirmationDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DeactivateOrdersConfirmationDialogComponent>,
    private manageOrdersFacade: ManageOrdersFacadeService,
  ) {
  }

  public onClickDeactivateOrders(): void {
    this.manageOrdersFacade.deactivateOrders$().subscribe(() => {
      this.dialogRef.close();
    });
  }
}
